import api from "../../utils/axios";

// 获取用户地址列表
export function AddressList(query) {
  return api({
    url: "/system/user/address/list",
    method: "post",
    data: query,
  });
}
// 新增用户地址
export function addAddress(query) {
  return api({
    url: "/system/user/address/add",
    method: "post",
    data: query,
  });
}
// 修改用户地址
export function editAddress(query) {
  return api({
    url: "/system/user/address/edit",
    method: "post",
    data: query,
  });
}
// 删除用户地址
export function delAddress(query) {
  return api({
    url: "/system/user/address/del",
    method: "post",
    data: query,
  });
}

// 修改手机号
export function editPhoneNumber(query) {
  return api({
    url: "/system/user/edit/phone/number",
    method: "post",
    data: query,
  });
}
// 刷新token
export function refreshTokenFn(query) {
  return api({
    url: "/auth/refresh",
    method: "post",
    data: query,
  });
}
// 注销
export function unsubscribe(query) {
  return api({
    url: "/auth/unsubscribe",
    method: "post",
    data: query,
  });
}
// 查询我的办件
export function querySingleRecord(query) {
  return api({
    url: "/transfer/common/authorized",
    method: "post",
    data: query,
  });
}
// 根据部门、情形ID查询材料和表单
export function getTemplateForm(query) {
  return api({
    url: "/zwmap/getDeptMaterialByDeptTemplate",
    method: "post",
    data: query,
  });
}
// 在线申请--提交   {idCard:'',phone:'',templateId:'',createBy:'',matterName:'',deptId:'',deptMatterId:[],materialList:[{deptMaterialId:'',url:'',idCard:''}],applyForm:{formValue:'',createBy:''}}
export function submitForm(query) {
  return api({
    url: "/zwmap/submit",
    method: "post",
    data: query,
  });
}
// 材料复用 deptMaterialId  idCard
export function MaterialReuse(query) {
  return api({
    url: "/zwmap/materialMultiplex",
    method: "post",
    data: query,
  });
}
// 退出登录   token userId
export function logout(query) {
  return api({
    url: "/zwmap/logout",
    method: "post",
    data: query,
  });
}
// 修改用户信息
export function updateUser(query) {
  return api({
    url: "/zwmap/updateUser",
    method: "post",
    data: query,
  });
}
// 查询用户材料 idCard
export function updateUserFiles(query) {
  return api({
    url: "/zwmap/selectUserFile",
    method: "post",
    data: query,
  });
}
// 更新用户材料   id  url
export function updateUserFile(query) {
  return api({
    url: "/zwmap/updateUserFile",
    method: "post",
    data: query,
  });
}
// 办件列表
export function getHandlingList(query) {
  return api({
    url: "/zwmap/getHandlingList",
    method: "post",
    data: query,
  });
}
// 办件详情
export function getHandlingDetails(query) {
  return api({
    url: "/zwmap/getHandlingDetails",
    method: "post",
    data: query,
  });
}

// 重新上传材料   提交材料id：idsubmitId;材料地址 url;申请id applyId;事项id matterId;
export function uploadMaterial(query) {
  return api({
    url: "/zwmap/uploadMaterial",
    method: "post",
    data: query,
  });
}

// 移动端接口
// 微信授权登录
export function login(query) {
  return api({
    url: "/register/user/login",
    method: "post",
    data: query,
  });
}
// 注册
export function register(query) {
  return api({
    url: "/register/user/register",
    method: "post",
    data: query,
  });
}
// 获取验证码
export function verify(query) {
  return api({
    url: "/register/user/verify",
    method: "post",
    data: query,
  });
}

// 在线申请无问卷
export function saveMobileBillInitializingWithCommon(query) {
  return api({
    url: "/transfer/common/authorized",
    method: "post",
    data: query,
  });
}

// 在线申请有问卷
export function saveMobileBillInitializing(data) {
  return api({
    url: `/transfer/common/authorized`,
    method: "post",
    data: data,
  });
}

// 在线申请详情
export function queryAddresseeInfo(data) {
  return api({
    url: `/transfer/common/authorized`,
    method: "post",
    data: data,
  });
}

// 在线申请提交
export function saveAddresseeInfo(data) {
  return api({
    url: `/transfer/common/authorized`,
    method: "post",
    data: data,
  });
}

// 材料上传
export function uploadCommon(data) {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return api({
    url: "/transfer/common/upload",
    method: "post",
    data: data,
    ...config,
  });
}

// 下载材料
export function download(data) {
  return api({
    url: `/transfer/common/download`,
    method: "get",
    responseType: "blob",
    params: data,
  });
}
// Token
export function refreshFileToken(data) {
  return api({
    url: '/transfer/common/refreshFileToken',
    method: 'post',
    data: data
  })
}
// 下载材料
export function downloadImg(data) {
  return api({
    url: `/transfer/common/baseDownload`,
    method: "get",
    params: data,
  });
}
// 鉴权接口
export function authorized(data) {
  return api({
     url: `/transfer/common/authorized`,
     method: "post",
     data: data,
   });
 } 
 // 鉴权接口
export function authorizedFeedback(data) {
  return api({
     url: `/transfer/common/authorizedFeedback`,
     method: "post",
     data: data,
   });
 } 