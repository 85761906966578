<template>
  <div class="personal">
    <div class="topBanner">
      <img
        style="width: 100%"
        src="../../assets/img/personal_banner.jpg"
        alt=""
      />
      <div class="topcon">
        <p class="isLoginBtn">*{{ $store.state.name.substring(1) }}，您好</p>
        <span class="isFaceAuth" v-if="$store.state.faceAuth == '1'">
          <img src="../../assets/img/card_icon.png" alt="" srcset="" />
          已人脸认证</span
        >
        <span
          class="isFaceAuth"
          v-show="$store.state.name != '' && $store.state.faceAuth == 0"
        >
          <img src="../../assets/img/card_icon.png" alt="" srcset="" />
          已实名认证</span
        >
      </div>
      <img class="logo" src="../../assets/img/index_logo_bg.png" alt="" />
    </div>
    <div class="cont">
      <div class="menuBox">
        <div class="menuItem" @click="toBasicInfo">
          <img src="../../assets/img/baseInfo_icon.png" alt="" />
          基本资料
        </div>
        <div class="menuItem" @click="toMyOffice">
          <img src="../../assets/img/myOffice_icon.png" alt="" />
          我的办事
        </div>
        <div class="menuItem" @click="toMyAddress">
          <img
            style="width: 22px"
            src="../../assets/img/myAddress_icon.png"
            alt=""
          />
          我的地址
        </div>
        <!-- <div class="menuItem" @click="toNotice">
          <img src="../../assets/img/system_msg.png" alt="" />
          系统通知
        </div> -->
        <div class="menuItem" @click="toMessage">
          <img src="../../assets/img/feed.png" alt="" />
          我的反馈
        </div>
      </div>
      <!-- <div class="bottom-btns">
        <div @click="toFaceAuth">人脸认证</div>
        <div @click="toEnterpriseAuth">企业认证</div>
      </div> -->
      <el-button
        style="width: 100%; margin-top: 30px"
        type="primary"
        @click="toIndex"
        >回到首页</el-button
      >
      <el-button
        style="width: 100%; margin-top: 30px; margin-left: 0"
        @click="loginOut"
        >注销</el-button
      >
    </div>
  </div>
</template>
<script>
import * as req from "@/api/map/index";
import * as api from "@/api/user/index";
export default {
  name: "Personal",
  data() {
    return {
      isShow: false,
    };
  },

  mounted() {
    if (this.isShow) {
      this.getUserInfo();
    }
  },
  beforeRouteEnter(to, from, next) {
    // to:获取你要跳转的路由信息
    // from: 获取你从哪个路由来的信息
    // next: （放行函数）
    if (from.name == null) {
      next((vm) => {
        vm.isShow = true;
      });
    } else {
      next(); // 直接放行
    }
  },
  methods: {
    // 人脸识别回调
    async getUserInfo() {
      let that = this;
      req
        .faceCallback({ sessionId: that.$store.state.sessionId })
        .then(async (res) => {
          // console.log(res, "用户信息");
          if (res.data.code == 200) {
            that.$store.commit("SET_TOKEN", res.data.token.access_token);
            that.$store.commit("SET_NAME", res.data.userInfo.sysUser.name);
            that.$store.commit(
              "SET_FACEAUTH",
              res.data.userInfo.sysUser.faceAuth
            );
            const loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
            loginInfo.token = res.data.token.access_token;
            localStorage.setItem("loginInfo", JSON.stringify(loginInfo));
            if (localStorage.getItem("userInfo")) {
              const userInfo = JSON.parse(localStorage.getItem("userInfo"));
              userInfo.faceAuth = res.data.userInfo.sysUser.faceAuth;
              localStorage.setItem("userInfo", JSON.stringify(userInfo));
            } else {
              let userInfo = {
                name: res.data.userInfo.sysUser.name,
                phonenumber: res.data.userInfo.sysUser.phonenumber,
                idCard: res.data.userInfo.sysUser.idCard,
                faceAuth: res.data.userInfo.sysUser.faceAuth,
              };
              localStorage.setItem("userInfo", JSON.stringify(userInfo));
            }
            that.$forceUpdate();
          } else {
            that.$alert(
              `<i class="el-icon-warning" style="color:red;font-size:50px"></i> <div style="color:red;">${res.data.msg}</div>`,
              "",
              {
                dangerouslyUseHTMLString: true,
                center: true,
                showConfirmButton: false,
              }
            );
          }
        });
    },
    // 回到首页
    toIndex() {
      this.$router.push("/");
    },
    // 退出登录
    loginOut() {
      let that = this;
      that
        .$confirm("此操作将退出登录，并注销个人信息, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          api.unsubscribe().then((res) => {
            if (res.data.code == 200) {
              that.$message({
                message: "注销成功！",
                type: "success",
              });
              that.$store.commit("SET_NAME", '');
              localStorage.removeItem("userInfo");
              localStorage.removeItem("loginInfo");
              localStorage.clear();
              setTimeout(() => {
                that.$router.push("/");
              }, 1000);
              that.$forceUpdate();
            } else {
              that.$alert(
                `<i class="el-icon-warning" style="color:red;font-size:50px"></i> <div style="color:red;">${res.data.msg}</div>`,
                "",
                {
                  dangerouslyUseHTMLString: true,
                  center: true,
                  showConfirmButton: false,
                }
              );
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 基本资料
    toBasicInfo() {
      this.$router.push("/basicInformation");
    },
    // 我的办事
    toMyOffice() {
      this.$router.push("/MyOffice");
    },
    // 我的地址
    toMyAddress() {
      this.$router.push("/myAddress");
    },
    // 系统通知
    toNotice() {
      this.$router.push("/systemNotice");
    },
    // 人脸认证
    toFaceAuth() {
      this.$router.push("/faceAuthentication");
    },
    // 企业认证
    toEnterpriseAuth() {
      this.$router.push("/enterpriseAuthentication");
    },
    // 我的留言
    toMessage () {
      this.$router.push("/leaveMessage");
    },
  },
};
</script>
<style scoped>
.topBanner {
  position: relative;
}
.logo {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
  width: 44px;
}
.topcon {
  position: absolute;
  left: 0px;
  bottom: 30px;
}
.isLoginBtn {
  text-align: left;
  margin-bottom: 5px;
  color: #fff;
  background-color: #0091fe;
  padding: 5px 10px;
  border-radius: 5px;
}
.topcon p {
  text-align: left;
  margin-bottom: 10px;
  color: #fff;
}
.qylabe {
  color: #fff;
  background-color: #f59a23;
  display: inline-block;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 5px 1px 14px 3px rgba(0, 0, 0, 0.31);
  font-size: 12px;
}
.isFaceAuth {
  color: #fff;
  display: flex;
  align-items: center;
  margin-left: 10px;
  justify-content: center;
  font-size: 14px;
}
.isFaceAuth img {
  width: 20px;
  margin-right: 5px;
}
.rllabe {
  color: #fff;
  display: inline-block;
  border-radius: 5px;
  padding: 5px;
  background-color: #0091fe;
  box-shadow: 5px 1px 14px 3px rgba(0, 0, 0, 0.31);
  margin-right: 10px;
  font-size: 12px;
}
.cont {
  margin: 20px;
}
.menuBox {
  height: 240px;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 5px 7px 25px #dee2e1;
}
.menuItem {
  padding: 10px;
  border-bottom: 1px solid #d3d3d3;
  text-align: left;
  font-size: 15px;
  font-weight: bold;
  color: #000;
}
.menuItem img {
  width: 25px;
  margin-right: 5px;
}
.bottom-btns {
  display: flex;
  justify-content: space-between;
  margin: 30px 10px;
  font-size: 14px;
  font-weight: bold;
}
</style>