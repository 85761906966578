<template>
  <div class="FuXiang"  v-loading="loading">
    <!--特色-->
    <div class="spring">
      <div class="banner">
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item, index) in springImgList" :key="index">
            <img
              width="100%"
              v-lazy="item.imgUrl"
              ref="imgH"
              @load="imgLoad"
              alt=""
              srcset=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/FuXiang/jianjie.png" alt="" />
        <div class="desc">
          <p>
            桃花李又叫做御黄李、玉皇李，其实是一个古老的品种，历史非常悠久。目前桃花李基本在四川汉源一带盛产，
            且当地的桃花李是从古至今流传下来的老品种，加上汉源优越的地理位置条件和充足的光照条件，使得所产的李子颗颗个大透亮，浓甜如蜜。
          </p>
          <p>
            大樱桃属于蔷薇科落叶乔木果树。樱桃成熟时颜色鲜红，玲珑剔透，味美形娇，营养丰富，医疗保健价值颇高，又有“含桃”的别称。
          </p>
          <p>
            花椒又名秦椒、蜀椒、川椒，多生于山坡、林缘、灌木丛中。花椒香气浓，为麻而持久多用于调味。花椒用作中药，有温中行气、逐寒、止痛、杀虫等功效。又作表皮麻醉剂。
          </p>
        </div>
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px; text-align: center">
        <img src="../../../assets/img/red.png" alt="" />
        <div class="bottom_br"></div>
      </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"
        color="#920C05"
      ></GovernmentServices>
    </div>
  </div>
</template>
<script>


import GovernmentServices from "@/components/GovernmentServices/index";
import { getServerDate, matterQuery } from "@/api/map/index";
import { mixin } from "../../../mixin";
export default {
  name: "FuXiang",
  components: {  GovernmentServices },
  data() {
    return {
      matterList: [], //事项列表
      villages: [], //乡镇列表
      name: "富乡乡",
      keywords: "",
      springImgList: [
        { imgUrl: require("../../../assets/img/FuXiang/banner.jpg") },
        { imgUrl: require("../../../assets/img/FuXiang/banner1.jpg") },
        { imgUrl: require("../../../assets/img/FuXiang/banner2.jpg") },
        { imgUrl: require("../../../assets/img/FuXiang/banner3.jpg") },
      ],
    };
  },
  mixins: [mixin],
  created() {
    this.$emit("public_header", false);
  },
};
</script>
<style scoped>
.banner {
  width: 100%;
  min-height: 215px;
  max-height: 427px;
}
.banner img {
  height: auto;
}
.con1 {
  position: relative;
  margin-top: -5px;
}
.con1 img {
  width: 100%;
}
.desc {
  font-size: 14px;
  color: #fff;
  position: absolute;
  top: 15%;
  left: 29%;
  overflow-y: auto;
  text-indent: 1em;
  width: 64%;
  height: 65%;
}
.search-box {
  margin: 0 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}
/deep/.spring .el-input__suffix {
  color: #cb221a;
  font-size: 20px;
}
/deep/.el-carousel__indicator.is-active button {
  background-color: #cb221a;
}
/deep/.el-carousel__button {
  background-color: gray;
}
</style>