<!-- 个人中心 -->
<template>
  <div class="myOffice">
    <div class="topTitle">我的办事</div>
    <div class="cont">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="已提交" name="0">
          <el-card class="box-card" v-for="item in list" :key="item.billNumber">
            <div slot="header" class="clearfix">
              <span class="text-overflow">{{ item.superMatterName }}</span>
            </div>
            <div class="card-cont">
              <div class="first-row">
                <div class="card-cont-tit">受理部门</div>
                <div class="gray-text">{{ item.deptName }}</div>
              </div>
              <div class="second-row">
                <div class="flex-center">
                  <div class="card-cont-tit">申请时间</div>
                  <div class="gray-text">{{ item.createTime }}</div>
                </div>
                <el-button
                  size="mini"
                  type="primary"
                  plain
                  @click="toDetail(item)"
                  >详情</el-button
                >
              </div>
            </div>
          </el-card>
          <el-empty v-show="list.length == 0" description="暂无已提交事项"></el-empty>
          <!-- <div class="empty" v-show="list.length == 0">
            <img src="../../assets/img/myOfficeEmpty_icon.png" alt="" />
            <p>暂无已提交事项</p>
          </div> -->
        </el-tab-pane>
        <el-tab-pane label="已受理" name="1">
          <el-card
            v-show="list.length > 0"
            class="box-card"
            v-for="item in list"
            :key="item.billNumber"
          >
            <div slot="header" class="clearfix">
              <span class="text-overflow">{{ item.superMatterName }}</span>
            </div>
            <div class="card-cont">
              <div class="first-row">
                <div class="card-cont-tit">受理部门</div>
                <div class="gray-text">{{ item.deptName }}</div>
              </div>
              <div class="second-row">
                <div class="flex-center">
                  <div class="card-cont-tit">申请时间</div>
                  <div class="gray-text">{{ item.createTime }}</div>
                </div>
                <el-button
                  size="mini"
                  type="primary"
                  plain
                  @click="toDetail(item)"
                  >详情</el-button
                >
              </div>
            </div>
          </el-card>
          <el-empty v-show="list.length == 0" description="暂无已受理事项"></el-empty>
          <!-- <div class="empty" v-show="list.length == 0">
            <img src="../../assets/img/myOfficeEmpty_icon.png" alt="" />
            <p>暂无已受理事项</p>
          </div> -->
        </el-tab-pane>
        <el-tab-pane label="已办结" name="2,3">
          <el-card class="box-card" v-for="item in list" :key="item.billNumber">
            <div slot="header" class="clearfix">
              <span class="text-overflow">{{ item.superMatterName }}</span>
            </div>
            <div class="card-cont">
              <div class="first-row">
                <div class="card-cont-tit">受理部门</div>
                <div class="gray-text">{{ item.deptName }}</div>
              </div>
              <div class="second-row">
                <div class="flex-center">
                  <div class="card-cont-tit">申请时间</div>
                  <div class="gray-text">{{ item.createTime }}</div>
                </div>
                <el-button
                  size="mini"
                  type="primary"
                  plain
                  @click="toDetail(item)"
                  >详情</el-button
                >
              </div>
            </div>
          </el-card>
          <el-empty v-show="list.length == 0" description="暂无已办结事项"></el-empty>
          <!-- <div class="empty" v-show="list.length == 0">
            <img src="../../assets/img/myOfficeEmpty_icon.png" alt="" />
            <p>暂无已办结事项</p>
          </div> -->
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import * as req from "@/api/user/index";
export default {
  data() {
    return {
      activeName: "",
      handleStatus: [0],
      list: [],
    };
  },

  mounted() {
    this.getList(); //获取办件列表
    this.$forceUpdate();
  },

  methods: {
    // 查询办件列表
    getList() {
      let obj = {
        method: "/mobile/querySingleRecord",
        body: JSON.stringify({
          idCard: JSON.parse(localStorage.getItem("userInfo")).idCard,
          handleStatus: this.handleStatus,
        }),
      };
      req.querySingleRecord(obj).then((res) => {
        if (res.data && res.data.code == 200) {
          this.list = res.data.data || [];
        } else {
          this.$alert(
            `<i class="el-icon-warning" style="color:red;font-size:50px"></i> <div style="color:red;">${res.data.msg}</div>`,
            "",
            {
              dangerouslyUseHTMLString: true,
              center: true,
              showConfirmButton: false,
            }
          );
        }
      });
    },
    // 菜单切换
    handleClick(tab, event) {
      // console.log(tab,'///');
      this.handleStatus = [];
      this.handleStatus = tab.name.split(",");
      this.getList();
    },
    toDetail(item) {
      this.$router.push({
        name: "BusinessDetails",
        params: item,
      });
    },
  },
};
</script>
<style scoped>
.myOffice {
  margin: 20px;
}
.text {
  font-size: 14px;
}
.empty {
  margin: 60px;
  text-align: center;
}
.empty img {
  width: 100%;
  margin-bottom: 20px;
}
.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.box-card {
  text-align: left;
  margin-bottom: 10px;
}
/deep/ .el-card__header {
  padding: 10px;
  font-weight: bold;
  font-size: 14px;
}
.text-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  width: 100%;
}
.second-row {
  display: flex;
  width: 100%;
  margin: 10px 0;
  align-items: center;
  justify-content: space-between;
}
.flex-center {
  display: flex;
  justify-content: center;
}
.gray-text {
  font-size: 14px;
  color: gray;
}
.card-cont-tit {
  font-size: 14px;
  font-weight: bold;
  margin-right: 20px;
}
.first-row {
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
}
</style>