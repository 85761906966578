<template>
  <div class="DaShu" v-loading="loading">
    <!--春季：3-5月-->
    <div class="spring" v-if="nowMoth >= 3 && nowMoth <= 5">
      <div class="banner">
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item,index) in springImgList" :key=index>
            <img ref="imgH" @load="imgLoad"
              width="100%"
              v-lazy="item.imgUrl"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/DaShu/spring_jianjie.png" alt="">
        <div class="desc">
          <p>
            大五星号称枇杷之王，是我国目前最大果型的枇杷品种之一。曾获“99昆明世界园艺博览会’’银质奖。其主要特点是果实大，商品性好，果核小，果肉厚。平均单果重79克，最大果重194克。果实含可溶性固形物13.5%。果皮色泽金黄，外观诱人。丰产性好。大树镇2021年枇杷种植3000亩左右，产地主要位于新民村、料林村和山羊坪社区。
          </p>
          <p>
            大树镇2021年黄果柑种植约4000亩,主要种植于松林村、山羊坪社区。黄果柑是由橘和橙天然杂交而来的柑橘品种，果味酸甜适口，果粒饱满多汁，在汉源有300多年种植历史，当地人俗称青果、黄金果，素有“迎春第一果”之称。黄果柑属于无核品种，果粒饱满，带着柑橘的自然酸甜清香，一口咬下去，饱满柔嫩，润甜适口，含有丰富的维生素C、钙、B-胡萝卜素、柠檬酸、等营养物质，能够有效抗氧化、抗衰老、增强人体的免疫力。还含有膳食纤维和果胶，能够降低体内的胆固醇，对预防冠心病、动脉硬化等疾病有很好的作用。
          </p>
        </div>
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px;text-align:center;">
          <img src="../../../assets/img/orange.png" alt="">
          <div class="bottom_br"></div>
        </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"        
        color="#DC6A06"
      ></GovernmentServices>
    </div>
    <!-- 夏季：6-8月 -->
    <div class="summer" v-else-if="nowMoth >= 6 && nowMoth <= 8">
      <div class="banner">
        <!-- <img
          width="100%"
          src="../../../assets/img/DaShu/summer_banner.jpg"
          alt=""
          srcset=""
        /> -->
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item,index) in sunnerImgList" :key=index>
            <img ref="imgH" @load="imgLoad"
              width="100%"
              v-lazy="item.imgUrl"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/DaShu/summer_jianjie.png" alt="">
        <div class="desc">
          大树镇葡萄观光采摘园位于大树镇麦坪社区、新民村，占地1000余亩，前期主要葡萄品种：夏黑、户太八号、美人指、金手指、巨玫瑰、红乳、红提、红富士、阳光玫瑰、克伦森等优质葡萄品种10余种，是全县最大规模的集生产、观光、科教于一体的连片葡萄采摘观光园。汉源县大树镇的葡萄取名为“汉源阳光葡萄”，因为阳光代表的是健康、欣欣向荣，“汉源阳光葡萄”也是健康的、安全的、营养的。“汉源阳光葡萄”拥有葡萄种植得天独厚的自然条件，昼夜温差大，阳光充足，空气清新，水质优良，是夏黑、巨玫瑰、美人指、金手指等多种葡萄品种种植的最佳生态区域。规范化的建园、标准化的栽培管理更造就了“汉源阳光葡萄”的优秀品质，大树镇“月亮湾葡萄园”也成为“汉源阳光葡萄”品牌的基地。
        </div>
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px;text-align:center;">
          <img src="../../../assets/img/green.png" alt="">
          <div class="bottom_br"></div>
        </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"
        color="#4DB12E"
      ></GovernmentServices>
    </div>
    <!-- 秋季：9-11月 -->
    <div class="autumn" v-else>
      <div class="banner">
        <!-- <img
          width="100%"
          src="../../../assets/img/DaShu/autumn_banner.jpg"
          alt=""
          srcset=""
        /> -->
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item,index) in autumnImgList" :key=index>
            <img ref="imgH" @load="imgLoad"
              width="100%"
              v-lazy="item.imgUrl"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/DaShu/autumn_jianjie.png" alt="">
        <div class="desc">
          大树镇高山中药材种植地主要分布于大维村、白玉村与桂贤村，药材种类主要有当归、牛膝、党参、重楼，种植面积达2000亩，2022年预计总产值2500万元。海拔千米以上的高山地区与低山相比气温明显较低，尤其是夏季，高山气候凉爽宜人，日夜温差较大，降雨充沛，植被茂密，常年云雾缭绕，空气、土壤、环境湿度较高，紫外光强，但林缘、林下直射光较弱，土壤大部分为黄棕壤、白鳝土，有机质含量较高，这些条件恰是大多数中药材（尤其是根茎类药材）生长所必需的。很多药材（如党参）只有在高山生长才有较高的药用价值与经济价值。
        </div>
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px;text-align:center;">
          <img src="../../../assets/img/brown.png" alt="">
          <div class="bottom_br"></div>
        </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"        
        color="#8D582A"
      ></GovernmentServices>
    </div>
  </div>
</template>
<script>


import GovernmentServices from "@/components/GovernmentServices/index";
import { getServerDate, matterQuery } from "@/api/map/index";
import { mixin } from "../../../mixin";
export default {
  name: "DaShu",
  components: {  GovernmentServices },
  data() {
    return {
      matterList: [], //事项列表
      villages: [], //乡镇列表
      name: "大树镇",
      keywords:'',
      springImgList:[
        {imgUrl:require('../../../assets/img/DaShu/banner.jpg')},
        {imgUrl:require('../../../assets/img/DaShu/spring_banner1.jpg')},
        {imgUrl:require('../../../assets/img/DaShu/spring_banner2.jpg')}
      ],
      sunnerImgList: [
        {imgUrl:require('../../../assets/img/DaShu/banner.jpg')},
        {imgUrl:require('../../../assets/img/DaShu/summer_banner.jpg')},
      ],
      autumnImgList: [
        {imgUrl:require('../../../assets/img/DaShu/banner.jpg')},
        {imgUrl:require('../../../assets/img/DaShu/autumn_banner.jpg')},
      ],
    };
  },
  mixins: [mixin],
  created() {
    this.$emit("public_header", false);
  },
};
</script>
<style scoped>
.banner {
  width: 100%;
  min-height: 215px;
  max-height: 427px;
}
.banner img{
  height: auto;
}
.con1 {
  position: relative;
  margin-top: -5px;
}
.con1 img{
  width: 100%;
}
.desc {
  font-size: 14px;
  color: #fff;
  position: absolute;
  top: 15%;
  left: 29%;
  overflow-y: auto;
  text-indent: 1em;
  width: 64%;
  height: 65%;
}
.search-box {
  margin: 0 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}
/deep/.spring .el-input__suffix{
  color: #E79E5F;
  font-size: 20px;
}
/deep/.summer .el-input__suffix{
  color: #53B436;
  font-size: 20px;
}
/deep/.autumn .el-input__suffix{
  color: #AD6721;
  font-size: 20px;
}
/deep/.spring .el-carousel__indicator.is-active button {
  background-color: #E79E5F;
}
/deep/.summer .el-carousel__indicator.is-active button {
  background-color: #53B436;
}
/deep/.autumn .el-carousel__indicator.is-active button {
  background-color: #AD6721;
}
/deep/.el-carousel__button {
  background-color: gray;
}
</style>