import Vue from 'vue'
import Vuex from 'vuex'
import { saveUserInfo, wxLogin } from '@/api/map/index'
import { refreshTokenFn } from '@/api/user/index'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('loginInfo') ? JSON.parse(localStorage.getItem('loginInfo')).token : '',
    sessionId: JSON.parse(localStorage.getItem('loginInfo')) ? JSON.parse(localStorage.getItem('loginInfo')).sessionId : '',
    publicKey: JSON.parse(localStorage.getItem('loginInfo')) ? JSON.parse(localStorage.getItem('loginInfo')).publicKey : '',
    name: JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).name : '',
    faceAuth: JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).faceAuth : 0,
    idCard: JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).idCard : '',
    phonenumber: JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).phonenumber : '',
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_SESSIONID: (state, sessionId) => {
      state.sessionId = sessionId
    },
    SET_PUBLICKEY: (state, publicKey) => {
      state.publicKey = publicKey
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_FACEAUTH: (state, faceAuth) => {
      state.faceAuth = faceAuth
    },
    SET_IDCARD: (state, idCard) => {
      state.idCard = idCard
    },
    SET_PHONE: (state, phonenumber) => {
      state.phonenumber = phonenumber
    },
  },
  actions: {
    // 登录
    // Login({ commit }, obj) {
    //   console.log('$$$',obj)
    //   return new Promise((resolve, reject) => {
    //     wxLogin(obj).then(res => {
    //       setToken(res.data.data.token)
    //       commit('SET_TOKEN', res.data.data.token)
    //       commit('SET_NAME', res.data.data.name)
    //       commit('SET_SEX', res.data.data.sex)
    //       commit('SET_IDCARD', res.data.data.idCard)
    //       commit('SET_PHONE', res.data.data.phone)
    //       localStorage.setItem('token', res.data.data.token)
    //       console.log(this.state)
    //       resolve()
    //     }).catch(error => {
    //       reject(error)
    //     })
    //   })
    // },
    // doLogin({commit},userInfo) {
    //   console.log('$$$',userInfo)
    //   const { token, name,sex,idCard,phone} = userInfo;
    //   commit('SET_TOKEN', token);
    //   commit('SET_NAME', name);
    //   commit('SET_SEX', sex);
    //   commit('SET_IDCARD', idCard);
    //   commit('SET_PHONE', phone);
    //   // state.token = token;
    //   // state.name = name;
    //   // state.sex = sex;
    //   // state.idCard = idCard;
    //   // state.phone = phone;
    //   // console.log(this.state)
    // },

    // 退出系统
    // LogOut({ commit, state }) {
    //   return new Promise((resolve, reject) => {
    //     logout(state.token).then(() => {
    //       commit('SET_TOKEN', '')
    //       removeToken()
    //       location.reload();
    //       resolve()
    //     }).catch(error => {
    //       reject(error)
    //     })
    //   })
    // },

    // 刷新token
    // RefreshToken({ commit, state }) {
    //   return new Promise((resolve, reject) => {       
    //     refreshTokenFn().then((res) => {
    //       if (res.data.code == 200) {
    //         resolve()
    //       } else {
    //         reject()
    //       }
    //     }).catch((err) => {
    //       reject(err)
    //     }).finally(() => {

    //     })
    //   })
    // }
  },
  modules: {
  }
})
