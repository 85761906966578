<template>
  <div class="page-container">
    <div class="page-text">{{fileItem.title}}下载</div>
    <div class="page-list">
      <div class="page-item flex-row" @click="handleClick()">
        <span>{{ fileItem.name }}</span>
            <el-button type="primary" size="small" class="flex-row" icon='el-icon-download'>
              下载
            </el-button>
          </div>
    </div>
    <div class="layerTips" v-if='showTips'>
      <div class="layer-img"></div>
      <span @click="close" class="el-icon-circle-close layer-close-icon"></span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showTips: false,
      fileItem:{}
    };
  },
  mounted() {
    if (this.$route.params && this.$route.query.name) {
      console.log(this.$route.query)
      this.fileItem = {
        name: this.$route.query.name,
        filePath: this.$route.query.filePath,
        title: this.$route.query.title,
        link: this.$route.query.link,
        fileName: this.$route.query.fileName
      }
    }
  },
  methods: {
    close () {
      this.showTips = false;
    },
    checkUrl() {
      let ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true; // '微信浏览器'
      } else {
        return false; // 其他浏览器
      }
    },
    handleClick () {
      let is_weixin = this.checkUrl() //调用checkUrl 得到返回结果
      console.log(is_weixin)
				if (is_weixin) {
					this.showTips = true //可以理解为this.display绑定了 引导图img的display属性,当前是微信浏览器，展示引导图
				} else {
					this.showTips = false //当前为其他浏览器 ，隐藏引导图
					// window.location.href = this.path //讲之前存储的文件地址，赋值给当前 地址栏，即可预览或者下载文件了 
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = this.fileItem.link + `prod-api/transfer/common/download?filePath=${this.fileItem.filePath}&fileName=${this.fileItem.name}`
          console.log('下载路径', link.href)
          let filename = this.fileItem.fileName || this.fileItem.name;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link) // 下载完成移除元素 
				}
    },
  },
};
</script>


<style scoped>
.page-container{
  padding: 50px 20px 0;
}
.page-container .page-text{
    font-size: 18px;
    line-height: 32px;
    text-align: left;
    color: #333;
    margin-bottom: 30px;
  }
  .flex-row {
  display: flex;
  align-items: center;
}
.page-item {
  margin-top: 10px;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}
  .layerTips{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 1000px;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.6);
    text-align: center;
    line-height: 500px;
  }
  .layer-img{
    background: url('../assets/img/download-guide.png') no-repeat;
    background-size: contain;
    position: absolute;
    width: 75%;
    height: 200px;
    top: 20px;
    left: 20%;
  }
  .layer-close-icon{
    position: absolute;
    left: 20px;
    top: 20px;
    color: #ccc;
    font-size: 22px;
  }
</style>