<template>
  <div class="YongLi" v-loading="loading">
    <!-- 夏季：6-8月 -->
    <div class="summer" v-if="nowMoth >= 6 && nowMoth <= 8">
      <div class="banner">
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item, index) in springImgList" :key="index">
            <img
              width="100%"
              v-lazy="item.imgUrl"
              ref="imgH"
              @load="imgLoad"
              alt=""
              srcset=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/YongLi/summer_jianjie.png" alt="" />
        <div class="desc">
          <p>
            脆红李：永利乡土壤肥沃，天气适宜，是种植脆红李的最佳位置，这里种植的脆红李口感好，皮脆而不涩，且果肉含有多种维生素和氨基酸，而且生态无公害。
          </p>
          <p>
            莲花白：永利乡土地肥沃、气候湿润，是种植高山莲花白的最佳位置，这里种植的莲花白品质优良，而且生态无公害，一直以来深受各地客商的亲睐。
          </p>
        </div>
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px; text-align: center">
        <img src="../../../assets/img/red.png" alt="" />
        <div class="bottom_br"></div>
      </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"
        color="#B5332B"
      ></GovernmentServices>
    </div>
    <!-- 秋季：9-11月 -->
    <div class="autumn" v-else-if="nowMoth >= 9 && nowMoth <= 11">
      <div class="banner">
        <!-- <img
          width="100%"
          src="../../../assets/img/YongLi/autumn_banner.jpg"
          alt=""
          srcset=""
        /> -->
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item, index) in autumnImgList" :key="index">
            <img
              width="100%"
              v-lazy="item.imgUrl"
              ref="imgH"
              @load="imgLoad"
              alt=""
              srcset=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/YongLi/autumn_jianjie.png" alt="" />
        <div class="desc">
          核桃：核桃营养丰富，适合各种体质的人群食用。它的食用方法也有很多种除了生吃核桃仁之外，也可煮食、炒食、蜜炙、油炸等。
        </div>
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px; text-align: center">
        <img src="../../../assets/img/brown.png" alt="" />
        <div class="bottom_br"></div>
      </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"
        color="#7B4411"
      ></GovernmentServices>
    </div>
    <!-- 冬季：1、2、12月 -->
    <div
      class="winter"
      v-else-if="(nowMoth >= 1 && nowMoth <= 2) || nowMoth == 12"
    >
      <div class="banner">
        <!-- <img
          width="100%"
          src="../../../assets/img/YongLi/winter_banner.jpg"
          alt=""
          srcset=""
        /> -->
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item, index) in winterImgList" :key="index">
            <img
              width="100%"
              v-lazy="item.imgUrl"
              ref="imgH"
              @load="imgLoad"
              alt=""
              srcset=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/YongLi/winter_jianjie.png" alt="" />
        <div class="desc">
          高山独特的无公害有机苹果具有良好的果形，亮丽的果色，外形硕大，口感松，水分充足，糖分高，一直以来深受各地客商的亲睐。
        </div>
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px; text-align: center">
        <img src="../../../assets/img/red.png" alt="" />
        <div class="bottom_br"></div>
      </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"
        color="#9C0014"
      ></GovernmentServices>
    </div>
    <!--四季-->
    <div class="spring" v-else>
      <div class="banner">
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item,index) in imgList" :key=index>
            <img width="100%" v-lazy="item.imgUrl" ref="imgH" @load="imgLoad" alt="" srcset="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/YongLi/jianjie.png" alt="" />
        <div class="desc">
          <p>
            腊肉：腊肉外黑油亮，耐贮存。经清洗煮、蒸、炒后可食用，肉质红亮、肥而不腻、味香醇美。为当地待客和馈赠宾朋的土特产品，畅销外地。由永利产高山新鲜猪肉用盐腌制后，挂在火炉上方，让燃烧的柏树和香樟树枝的烟熏烤后风干而成。
          </p>
          <p>
            跑山羊：随着生态农产品越来越受市场欢迎，永利乡距离县城约80公里，山间长满了翠色欲流的青草，具有得天独厚的自然生态环境。当地村民借助山区林草丰富的资源优势，养起了“跑山羊”，吃起了生态饭。
          </p>
          <p>
            黄牛肉：永利乡家家都有养殖黄牛，其养殖简单，只要以大自然青草为主食，农家种植的芍藤、稻谷杆为辅，一般黄牛3-5年便可成形。正宗黄牛吸收天然灵气，善于运动，所以黄牛肉肉质滑嫩，营养价值高，经常出现在餐桌上，吃起来还有一点点嚼劲。
          </p>
        </div>
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px; text-align: center">
        <img src="../../../assets/img/red.png" alt="" />
        <div class="bottom_br"></div>
      </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"
        color="#AE1C13"
      ></GovernmentServices>
    </div>
  </div>
</template>
<script>


import GovernmentServices from "@/components/GovernmentServices/index";
import { getServerDate, matterQuery } from "@/api/map/index";
import { mixin } from "../../../mixin";
export default {
  name: "YongLi",
  components: {  GovernmentServices },
  data() {
    return {
      matterList: [], //事项列表
      villages: [], //乡镇列表
      name: "永利彝族乡",
      keywords: "",
      springImgList: [
        { imgUrl: require("../../../assets/img/YongLi/banner.jpg") },
        { imgUrl: require("../../../assets/img/YongLi/summer_banner1.jpg") },
        { imgUrl: require("../../../assets/img/YongLi/summer_banner2.jpg") },
      ],
      imgList: [
        { imgUrl: require("../../../assets/img/YongLi/banner.jpg") },
        { imgUrl: require("../../../assets/img/YongLi/banner1.jpg") },
        { imgUrl: require("../../../assets/img/YongLi/banner2.jpg") },
        { imgUrl: require("../../../assets/img/YongLi/banner3.jpg") },
      ],
      winterImgList: [
        { imgUrl: require("../../../assets/img/YongLi/banner.jpg") },
        { imgUrl: require("../../../assets/img/YongLi/winter_banner.jpg") },
      ],
      autumnImgList: [
        { imgUrl: require("../../../assets/img/YongLi/banner.jpg") },
        { imgUrl: require("../../../assets/img/YongLi/autumn_banner.jpg") },
      ],
    };
  },
  mixins: [mixin],
  created() {
    this.$emit("public_header", false);
  },
};
</script>
<style scoped>
.banner {
  width: 100%;
  min-height: 215px;
  max-height: 427px;
}
.con1 {
  position: relative;
  margin-top: -5px;
}
.con1 img {
  width: 100%;
}
.desc {
  font-size: 14px;
  color: #fff;
  position: absolute;
  top: 15%;
  left: 29%;
  overflow-y: auto;
  text-indent: 1em;
  width: 64%;
  height: 65%;
}
.search-box {
  margin: 0 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}
/deep/.spring .el-input__suffix {
  color: #ae1c13;
  font-size: 20px;
}
/deep/.summer .el-input__suffix {
  color: #b5332b;
  font-size: 20px;
}
/deep/.autumn .el-input__suffix {
  color: #82422f;
  font-size: 20px;
}
/deep/.winter .el-input__suffix {
  color: #9c0014;
  font-size: 20px;
}
/deep/.spring .el-carousel__indicator.is-active button {
  background-color: #ae1c13;
}
/deep/.summer .el-carousel__indicator.is-active button {
  background-color: #b5332b;
}
/deep/.autumn .el-carousel__indicator.is-active button {
  background-color: #82422f;
}
/deep/.winter .el-carousel__indicator.is-active button {
  background-color: #9c0014;
}
/deep/.el-carousel__button {
  background-color: gray;
}
</style>