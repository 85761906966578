<template>
  <div class="feedback">
    <div class="logo_box" v-if='type=="form"'>
      <img width="100%" src="../assets/img/feed_title.jpg" alt="" />
    </div>
    <div class="feed-field">
      <el-form
      v-if='type=="form"'
      :model="ruleForm"
      status-icon
      :rules="rules"
      ref="ruleForm"
      label-width="95px"
      class="demo-ruleForm"
    >
      <el-form-item label="姓名" prop="name">
        <el-input
          v-model="ruleForm.name"
          autocomplete="off"
          disabled
          placeholder="姓名"
        ></el-input>
      </el-form-item>
      <el-form-item label="手机号码" prop="phoneNumber">
        <el-input
          v-model="ruleForm.phoneNumber"
          autocomplete="off"
          disabled
          placeholder="手机号码"
        ></el-input>
      </el-form-item>
      <el-form-item label="反馈标题" prop="title">
        <el-input
          v-model="ruleForm.title"
          autocomplete="off"
          maxlength="10"
          show-word-limit
          placeholder="请输入反馈标题"
        ></el-input>
      </el-form-item>
      <el-form-item class="input-content" label="反馈内容" prop="content">
        <el-input
          v-model="ruleForm.content"
          autocomplete="off"
          maxlength="500"
          rows="5"
          type="textarea"
          show-word-limit
          placeholder="请输入反馈内容"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="feed-over" v-else>
      <img src="../assets/img/feedOver.png" alt="" />
      <div class="text">感谢您的反馈</div>
    </div>
    <el-button
      class="bottom_btn"
      type="primary"
      v-if="type=='form'"
      :loading="loading"
      @click="submitForm('ruleForm')"
      >提交</el-button
    >
    <el-button
      type="primary"
      class="bottom_btn bottom_btn-mini"
      v-else
      @click="goBack()"
      >返回首页</el-button
    >
    </div>
  </div>
</template>

<script>
import { authorizedFeedback } from "@/api/user/index";
export default {
  data() {
    return {
      loading: false,
      ruleForm: {
        name: "",
        phoneNumber: "",
        title: "",
        content: "",
        sessionId: "",
      },
      type: 'form',
      countdown: 0,
      timer: null,
      loginInfo: {},
      userInfo: {},
      rules: {
        name: [
          { required: true, trigger: "blur", message: "请输入您的姓名" },
        ],
        phoneNumber: [
          { required: true, trigger: "blur", message: "请输入您的电话号码" },
        ],
        title: [
          { required: true, trigger: "blur", message: "请输入反馈标题" },
          { min: 4, max: 10, message: '反馈标题长度为 4 到 10 个字符', trigger: 'blur' }
        ],
        content: [
          { required: true, trigger: "blur", message: "请输入反馈内容" },
          { min: 5, max: 500, message: '反馈内容长度为 10 到 500 个字符', trigger: 'blur' }
        ],
      },
    };
  },
  mounted() {
    this.loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.ruleForm.sessionId = this.loginInfo.sessionId;
    // console.log('userInfo', this.userInfo);
    this.ruleForm.name = this.userInfo.name;
    this.ruleForm.phoneNumber = this.userInfo.phonenumber;
    this.resetForm();
    this.$forceUpdate();
  },
  methods: {
    goBack () {
      this.$router.push("/");
    },
    resetForm () {
      this.type = 'form';
      this.ruleForm.title = '';
      this.ruleForm.content = '';
    },
    // 提交注册
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          authorizedFeedback({
             method: "/mobile/feedbackAdd",
             body: JSON.stringify(this.ruleForm),
          }).then((res) => {
            if (res.data.code == 200) {
              this.type = 'info';
              // this.$message.success("提交成功！");
              this.loading = false;
            } else {
              this.loading = false;
              this.$alert(
                `<i class="el-icon-warning" style="color:red;font-size:50px"></i> <div style="color:red;">${res.data.msg}</div>`,
                "",
                {
                  dangerouslyUseHTMLString: true,
                  center: true,
                  showConfirmButton: false,
                }
              );
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>


<style scoped>
.feed-field {
  margin: 20px;
}
form {
  border: none !important;
}
.bottom_btn{
  position: absolute;
  bottom: 20px;
  width: calc(100% - 40px);
}
.bottom_btn-mini{
  width: 40%;
  left: 30%;
}
.feed-over{
  text-align: center;
  font-size: 14px;
  padding-top: 60px;
  /* color: #c2c2c2; */
}
/deep/ form {
  border: 1px solid gray;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 30px;
}
/deep/.el-form-item__label {
  text-align: left;
}
/deep/.el-form-item {
  border-bottom: 1px solid #c2c2c2;
}
/deep/.el-input__inner {
  border: none;
}
/deep/.el-textarea__inner{
  border: none;
  line-height: 40px;
  color: #606266;
  padding: 0 15px;
}
.input-content{
  padding-top: 6px;
}
/deep/.input-content .el-form-item__label, /deep/.input-content .el-form-item__content, /deep/.input-content .el-textarea__inner{
  line-height: 26px !important;
}
</style>