<template>
  <div class="JiuXiang" v-loading="loading">
    <!--春季：1-3月-->
    <div class="spring" v-if="nowMoth >= 3 && nowMoth <= 5">
      <div class="banner">
        <!-- <img
          width="100%"
          src="../../../assets/img/JiuXiang/spring_banner.jpg"
          alt=""
          srcset=""
        /> -->
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item,index) in springImgList" :key=index>
            <img
              width="100%"
              v-lazy="item.imgUrl" ref="imgH" @load="imgLoad"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/JiuXiang/spring_jianjie.png" alt="" />
        <div class="desc">
          梨花，蔷薇科梨属，梨树的花朵。梨树，落叶乔木，叶圆如大叶杨，干有粗皮外护，枝撑如伞。春季开花，花色洁白,如同雪花，具有淡淡的香味。梨可供生食外，还可酿酒、制梨膏、梨脯,以及药用。
        </div>
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px; text-align: center">
        <img src="../../../assets/img/blue.png" alt="" />
        <div class="bottom_br"></div>
      </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"
        color="#2E80C6"
      ></GovernmentServices>
    </div>
    <!-- 夏季：4-6月 -->
    <div class="summer" v-else-if="nowMoth >= 6 && nowMoth <= 8">
      <div class="banner">
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item, index) in summerImgList" :key="index"
            >
            <img
              width="100%"
              v-lazy="item.imgUrl"
              ref="imgH"
              @load="imgLoad"
              alt=""
              srcset=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/JiuXiang/winter_jianjie.png" alt="" />
        <div class="desc">
          <p>
            枇杷是美观的果树，果味酸甜，供鲜食、蜜饯与酿酒用；树叶晒干去毛可供药用，有化痰止咳、和胃降气之效；枇杷木材红棕色，可制作木梳、手杖、农具柄等。
          </p>
          <p>
            车厘子(英文名：CHERRIES)是欧洲甜樱桃栽培品种。音译自英语单词CHERRIES(即樱桃)，特指产于美国、加拿大、智利等美洲国家的个大皮厚樱桃。车厘子是上市最早的一种乔木果实，号称“百果第一枝”。落叶灌木果树，车厘子多呈暗红色，也有红、黄色，直径较大，新鲜的车厘子较硬，果柄较长。
          </p>
        </div>
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px; text-align: center">
        <img src="../../../assets/img/red.png" alt="" />
        <div class="bottom_br"></div>
      </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"
        color="#A50200"
      ></GovernmentServices>
    </div>
    <!-- 秋季：7-9月 -->
    <div class="autumn" v-else-if="nowMoth >= 9 && nowMoth <= 11">
      <div class="banner">
        <!-- <img
          width="100%"
          src="../../../assets/img/JiuXiang/autumn_banner.jpg"
          alt=""
          srcset=""
        /> -->
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item, index) in autumnImgList" :key="index"
            >
            <img
              width="100%"
              v-lazy="item.imgUrl"
              ref="imgH"
              @load="imgLoad"
              alt=""
              srcset=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/JiuXiang/autumn_jianjie.png" alt="" />
        <div class="desc">
          汉源花椒有着悠久的栽培历史，汉源被誉为“中国花椒之乡”。汉源花椒自唐代起就被正式列为贡品，故又名“贡椒”，以色泽丹红、粒大油重、芳香浓郁、醇麻爽口蜚声中外，2005年获得地理标志保护产品。
        </div>
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px; text-align: center">
        <img src="../../../assets/img/dark blue.png" alt="" />
        <div class="bottom_br"></div>
      </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"
        color="#1D3492"
      ></GovernmentServices>
    </div>
    <!-- 冬季：10-12月 -->
    <div class="winter" v-else>
      <div class="banner">
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item, index) in winterImgList" :key="index"
            >
            <img
              width="100%"
              v-lazy="item.imgUrl"
              ref="imgH"
              @load="imgLoad"
              alt=""
              srcset=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/JiuXiang/winter_jianjie.png" alt="" />
        <div class="desc">
          <p>
            黄果柑，柑橘品种，当地人俗称青果、黄金果，主要分布于大渡河流域石棉、汉源等干热河谷地带。黄果柑由橘和橙天然杂交而来，在当地有300多年种植历史。
          </p>
          <p>
            九襄黄牛肉因其蛋白质含量高、脂肪含量低、味道鲜美而受人喜爱，享有“肉中骄子”的美称。
          </p>
        </div>
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px; text-align: center">
        <img src="../../../assets/img/red.png" alt="" />
        <div class="bottom_br"></div>
      </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"
        color="#AC0D0A"
      ></GovernmentServices>
    </div>
  </div>
</template>
<script>


import GovernmentServices from "@/components/GovernmentServices/index";
import { getServerDate, matterQuery } from "@/api/map/index";
import { mixin } from "../../../mixin";
export default {
  name: "JiuXiang",
  components: {  GovernmentServices },
  data() {
    return {
      matterList: [], //事项列表
      villages: [], //乡镇列表
      name: "九襄镇",
      keywords: "",
      summerImgList: [
        {imgUrl:require('../../../assets/img/JiuXiang/banner.jpg')},
        { imgUrl: require("../../../assets/img/JiuXiang/summer_banner1.jpg") },
        { imgUrl: require("../../../assets/img/JiuXiang/summer_banner2.jpg") },
      ],
      winterImgList: [
        {imgUrl:require('../../../assets/img/JiuXiang/banner.jpg')},
        { imgUrl: require("../../../assets/img/JiuXiang/winter_banner1.jpg") },
        { imgUrl: require("../../../assets/img/JiuXiang/winter_banner2.jpg") },
      ],
      springImgList: [
        {imgUrl:require('../../../assets/img/JiuXiang/banner.jpg')},
        {imgUrl:require('../../../assets/img/JiuXiang/spring_banner.jpg')},
      ],
      autumnImgList: [
        {imgUrl:require('../../../assets/img/JiuXiang/banner.jpg')},
        {imgUrl:require('../../../assets/img/JiuXiang/autumn_banner.jpg')},
      ],
    };
  },
  mixins: [mixin],
  created() {
    this.$emit("public_header", false);
  },
};
</script>
<style scoped>
.banner {
  width: 100%;
  min-height: 215px;
  max-height: 427px;
}
.con1 {
  position: relative;
  margin-top: -5px;
}
.con1 img {
  width: 100%;
}
.desc {
  font-size: 14px;
  color: #fff;
  position: absolute;
  top: 15%;
  left: 29%;
  overflow-y: auto;
  text-indent: 1em;
  width: 64%;
  height: 65%;
}
.search-box {
  margin: 0 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}
/deep/.spring .el-input__suffix {
  color: #2e80c6;
  font-size: 20px;
}
/deep/.summer .el-input__suffix {
  color: #a50200;
  font-size: 20px;
}
/deep/.autumn .el-input__suffix {
  color: #1d3492;
  font-size: 20px;
}
/deep/.winter .el-input__suffix {
  color: #ac0d0a;
  font-size: 20px;
}
/deep/.spring  .el-carousel__indicator.is-active button {
  background-color: #2e80c6;
}
/deep/.summer  .el-carousel__indicator.is-active button {
  background-color: #a50200;
}
/deep/.autumn  .el-carousel__indicator.is-active button {
  background-color: #1d3492;
}
/deep/.winter  .el-carousel__indicator.is-active button {
  background-color: #ac0d0a;
}
/deep/.el-carousel__button {
  background-color: gray;
}
</style>