import { getServerDate, matterQuery,getVillages} from "@/api/map/index";
export const mixin = {
  data() {
    return {
      loading:false,
      nowMoth: 6, //当前月份
      imgHeight: '0', //轮播图组件高度
      mapId:[],
    }
  },
  mounted() {
    this.getData()
    this.GetVillages()
    // setTimeout(() => {
    //   this.getMatterList()
    // }, 500);
    this.imgLoad();
    window.addEventListener("resize",this.imgLoad,false);
  },
  methods: {
    // 获取服务器当前时间
    getData() {
      getServerDate( {
        method: "/zwmap/getDate",
        body: '',
      }).then(res => {
        // console.log(res,'时间')
        if (res.data.code == 200) {
          this.FormatData(res.data.data)
        }
      })
    },
    // 获取乡镇列表
    GetVillages() {
      // type为3查询乡镇列表
      let obj={
        method:'/mobile/getVillages',
        body:JSON.stringify({type:3})
      }
      this.loading = true;
      getVillages(obj).then(res => {
        // console.log(res, '乡镇列表')
        if (res.status == 200) {
          if (res.data.data && res.data.data.length > 0) {
            let arrList = res.data.data;
            arrList.unshift({ type: null, hanYuanMapMobileVOs:[ {id:"fa08802c1d4d11eda7660242ac120002"}], name: "县本级" });
            for (let index = 0; index < arrList.length; index++) {
              switch (arrList[index].name.slice(0, 2)) {
                case '县本':
                  arrList[index].url = 'HanYuan'
                  break;
                case '安乐':
                  arrList[index].url = 'AnLe'
                  break;
                case '大树':
                  arrList[index].url = 'DaShu'
                  break;
                case '富林':
                  arrList[index].url = 'FuLin'
                  break;
                case '富泉':
                  arrList[index].url = 'FuQuan'
                  break;
                case '富乡':
                  arrList[index].url = 'FuXiang'
                  break;
                case '富庄':
                  arrList[index].url = 'FuZhuang'
                  break;
                case '河南':
                  arrList[index].url = 'HeNan'
                  break;
                case '皇木':
                  arrList[index].url = 'HuangMu'
                  break;
                case '九襄':
                  arrList[index].url = 'JiuXiang'
                  break;
                case '马烈':
                  arrList[index].url = 'MaLie'
                  break;
                case '坭美':
                  arrList[index].url = 'NiMei'
                  break;
                case '片马':
                  arrList[index].url = 'PianMa'
                  break;
                case '前域':
                  arrList[index].url = 'QianYu'
                  break;
                case '清溪':
                  arrList[index].url = 'QingXi'
                  break;
                case '晒经':
                  arrList[index].url = 'ShaiJin'
                  break;
                case '顺河':
                  arrList[index].url = 'ShunHe'
                  break;
                case '唐家':
                  arrList[index].url = 'TangJia'
                  break;
                case '乌斯':
                  arrList[index].url = 'WuSiHe'
                  break;
                case '小堡':
                  arrList[index].url = 'XiaoBao'
                  break;
                case '富泉':
                  arrList[index].url = 'FuQuan'
                  break;
                case '宜东':
                  arrList[index].url = 'YiDong'
                  break;
                case '永利':
                  arrList[index].url = 'YongLi'
                  break;
                default:
                  break;
              }
            }
            // console.log(arrList,'结果')
            this.villages = arrList;
            let obj = this.villages.find(el => el.name == this.name);
            if (obj) {
              let ids = []
              obj.hanYuanMapMobileVOs.forEach(el => {
                ids.push(el.id)
              });
              this.mapId = ids;
              if(this.name=='县本级'){
                sessionStorage.setItem('topTitle','汉源县政务服务中心' )
              }else{sessionStorage.setItem('topTitle', this.name)}
              this.loading = false;
              sessionStorage.setItem('lifeCycleDetails', JSON.stringify({name: this.name, id:this.mapId}));
            }
          }
        } else {
          this.$message.error(res.data.msg)
          this.loading = false;

        }
      })
    },
    // 到搜索页面
    toSearchView(){
      sessionStorage.setItem("mapIds", JSON.stringify(this.mapId))
      this.$router.push({
        name:'searchView',
        params:{mapIds:this.mapId}
      })
    },
    // 日期时间格式化
    FormatData(n) {
      // console.log(n, '时间')
      let now = new Date(n),
        // y = now.getFullYear();
        m = now.getMonth() + 1;
      // d = now.getDate();
      // return y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d) + " " + now.toTimeString().substr(0, 8);
      this.nowMoth = m;
    },
    // 获取轮播图片高度
    imgLoad(){
      this.$nextTick(()=>{
        if(this.$refs.imgH){
          this.imgHeight = `${this.$refs.imgH[0].height}px`;
        }        
      })
    }
  },
  destroyed () {
    window.removeEventListener("resize",this.imgLoad,false);
  },
}
