<template>
  <div class="YiDong" v-loading="loading">
    <!-- 夏季：6-8月 -->
    <div class="summer" v-if="nowMoth >= 6 && nowMoth <= 8">
      <div class="banner">
        <!-- <img
          width="100%"
          src="../../../assets/img/YiDong/summer_banner.jpg"
          alt=""
          srcset=""
        /> -->
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item,index) in summerImgList" :key=index>
            <img
              width="100%"
              v-lazy="item.imgUrl" ref="imgH" @load="imgLoad"
              alt=""
              srcset=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/YiDong/summer_jianjie.png" alt="" />
        <div class="desc">
          据宜东镇相关负责人介绍，种植户们常年坚持使用农家肥、科学种植农作物，有效保证了品质，口感到位同时农作物也很健康。
        </div>
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px; text-align: center">
        <img src="../../../assets/img/dark blue.png" alt="" />
        <div class="bottom_br"></div>
      </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"
        color="#394C9F"
      ></GovernmentServices>
    </div>
    <!-- 秋季：9-11月 -->
    <div class="autumn" v-else-if="nowMoth >= 9 && nowMoth <= 11">
      <div class="banner">
        <!-- <img
          width="100%"
          src="../../../assets/img/YiDong/autumn_banner.jpg"
          alt=""
          srcset=""
        /> -->
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item,index) in autumnImgList" :key=index>
            <img
              width="100%"
              v-lazy="item.imgUrl" ref="imgH" @load="imgLoad"
              alt=""
              srcset=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/YiDong/autumn_jianjie.png" alt="" />
        <div class="desc">
          <p>
            糖心苹果果核透明、果香浓郁、甘甜味厚、多汁无渣、果肉细腻，是一种蕴含了丰富的维生素C、纤维素、胡萝卜素、果胶等营养元素的水果。
          </p>
          <p>
            宜东镇是汉源县糖心苹果最具代表性的质量最高的产地，主要分布在三江村、大树村、永定村、林政村、新林村、海亭村六个村，我镇独特的光热资源和温差较大的气候特点，为糖心苹果的生长提供了极佳的自然条件，使苹果内产生糖分自然凝聚现象，故而形成糖心，经测试糖度高达16.7°。
          </p>
          <p>
            糖心苹果口感甜润清脆、肉质细腻、口味甘甜、外形红润美观，咀嚼后没有渣滓，糖心苹果口感好主要是因为昼夜温差大，采摘时间晚，糖分积累更多，因此口感甜润清脆、可口美味。而普通苹果：果肉结构比较松散、水分丰富，但是甜度不佳，咀嚼后有渣滓。所以相比而言糖心苹果一直深受广大消费者的喜爱。
          </p>
        </div>
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px; text-align: center">
        <img src="../../../assets/img/red.png" alt="" />
        <div class="bottom_br"></div>
      </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"
        color="#AC150C"
      ></GovernmentServices>
    </div>
    <!-- 冬季：1、2、12月 -->
    <div
      class="winter"
      v-else-if="(nowMoth >= 1 && nowMoth <= 2) || nowMoth == 12"
    >
      <div class="banner">
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item,index) in imgList" :key=index>
            <img
              width="100%"
              v-lazy="item.imgUrl" ref="imgH" @load="imgLoad"
              alt=""
              srcset=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/YiDong/winter_jianjie.png" alt="" />
        <div class="desc">
          <p>
            坛子肉，是我县特产，中国国家地理标志产品。坛子肉源起于宜东，因宜东古来便是茶马古道重要驿站，气候宜人，空气湿度较低，冬春干旱无严寒，夏秋多雨无酷热，适宜坛子肉保存，使得坛子肉具有存放1年而不变质的特点，从古至今，人们便将坛子肉作为肉熟制品保存的主要途径。坛子肉制作工艺用：选、腌、洗、炸、捞、贮6个字概括，精选猪肉修割好后放盐拌和均匀腌制24小时，腌制好后用清水漂洗干净，将猪板油放入大锅内炼制出油后放入清洗干净的猪肉，大火炸至猪肉成黄色后改用小火慢炸，直至猪肉表面呈金黄色后停止加热，最后将炸制好的猪肉放入消毒后的陶罐内，并将猪油一并倒入陶罐内至淹没肉块为止。整套生产流程无添加剂、不进行熏烤，最大程度保留了坛子肉的原汁原味。
          </p>
          <p>
            柿饼，中药名。为柿科植物柿的果实经加工而成的饼状食品，有白柿、乌柿两种。入药部位植物果实。炮制方法取成熟的柿子，削去外皮，日晒夜露，约经一月后，放置席圈内，再经一月左右，即成柿饼。
          </p>
        </div>
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px; text-align: center">
        <img src="../../../assets/img/red.png" alt="" />
        <div class="bottom_br"></div>
      </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"
        color="#AC150C"
      ></GovernmentServices>
    </div>
    <!--四季-->
    <div class="spring" v-else>
      <div class="banner">
        <!-- <img
          width="100%"
          src="../../../assets/img/YiDong/banner.jpg"
          alt=""
          srcset=""
        /> -->
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item,index) in springImgList" :key=index>
            <img
              width="100%"
              v-lazy="item.imgUrl" ref="imgH" @load="imgLoad"
              alt=""
              srcset=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/YiDong/jianjie.png" alt="" />
        <div class="desc">
          坛子肉，是我县特产，中国国家地理标志产品。坛子肉源起于宜东，因宜东古来便是茶马古道重要驿站，气候宜人，空气湿度较低，冬春干旱无严寒，夏秋多雨无酷热，适宜坛子肉保存，使得坛子肉具有存放1年而不变质的特点，从古至今，人们便将坛子肉作为肉熟制品保存的主要途径。坛子肉制作工艺用：选、腌、洗、炸、捞、贮6个字概括，精选猪肉修割好后放盐拌和均匀腌制24小时，腌制好后用清水漂洗干净，将猪板油放入大锅内炼制出油后放入清洗干净的猪肉，大火炸至猪肉成黄色后改用小火慢炸，直至猪肉表面呈金黄色后停止加热，最后将炸制好的猪肉放入消毒后的陶罐内，并将猪油一并倒入陶罐内至淹没肉块为止。整套生产流程无添加剂、不进行熏烤，最大程度保留了坛子肉的原汁原味。
        </div>
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px; text-align: center">
        <img src="../../../assets/img/red.png" alt="" />
        <div class="bottom_br"></div>
      </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"
        color="#AC150C"
      ></GovernmentServices>
    </div>
  </div>
</template>
<script>


import GovernmentServices from "@/components/GovernmentServices/index";
import { getServerDate, matterQuery } from "@/api/map/index";
import { mixin } from "../../../mixin";
export default {
  name: "YiDong",
  components: {  GovernmentServices },
  data() {
    return {
      matterList: [], //事项列表
      villages: [], //乡镇列表
      name: "宜东镇",
      keywords: "",
      imgList: [
        { imgUrl: require("../../../assets/img/YiDong/banner.jpg") },
        { imgUrl: require("../../../assets/img/YiDong/winter_banner1.jpg") },
        { imgUrl: require("../../../assets/img/YiDong/winter_banner2.jpg") },
      ],
      autumnImgList: [
        { imgUrl: require("../../../assets/img/YiDong/banner.jpg") },
        { imgUrl: require("../../../assets/img/YiDong/autumn_banner.jpg") },
      ],
      summerImgList: [
        { imgUrl: require("../../../assets/img/YiDong/banner.jpg") },
        { imgUrl: require("../../../assets/img/YiDong/summer_banner.jpg") },
      ],
      springImgList: [
        { imgUrl: require("../../../assets/img/YiDong/banner.jpg") },
        { imgUrl: require("../../../assets/img/YiDong/banner1.jpg") },
      ],
    };
  },
  mixins: [mixin],
  created() {
    this.$emit("public_header", false);
  },
};
</script>
<style scoped>
.banner {
  width: 100%;
  min-height: 215px;
  max-height: 427px;
}
.con1 {
  position: relative;
  margin-top: -5px;
}
.con1 img {
  width: 100%;
}
.desc {
  font-size: 14px;
  color: #fff;
  position: absolute;
  top: 15%;
  left: 29%;
  overflow-y: auto;
  text-indent: 1em;
  width: 64%;
  height: 65%;
}
.search-box {
  margin: 0 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}
/deep/.spring .el-input__suffix {
  color: #ac150c;
  font-size: 20px;
}
/deep/.summer .el-input__suffix {
  color: #394c9f;
  font-size: 20px;
}
/deep/.autumn .el-input__suffix {
  color: #ac150c;
  font-size: 20px;
}
/deep/.winter .el-input__suffix {
  color: #ac150c;
  font-size: 20px;
}
/deep/.summer .el-carousel__indicator.is-active button {
  background-color: #394c9f;
}
/deep/.el-carousel__indicator.is-active button {
  background-color: #ac150c;
}
/deep/.el-carousel__button {
  background-color: gray;
}
</style>