<template>
  <div class="BusinessDetails">
    <div class="topTitle">详情</div>
    <div class="list">
      <div class="list-item">
        <div class="list-item-title">事项名称</div>
        <div class="list-item-val">{{ metterObj.superMatterName }}</div>
      </div>
      <div class="list-item">
        <div class="list-item-title">受理部门</div>
        <div class="list-item-val">{{ metterObj.deptName }}</div>
      </div>
      <div class="list-item">
        <div class="list-item-title">申报时间</div>
        <div class="list-item-val">{{ metterObj.createTime }}</div>
      </div>
      <div class="list-item">
        <div class="list-item-title">法定办结时限</div>
        <div class="list-item-val">{{ metterObj.statutoryCompletion }}日</div>
      </div>
      <div class="list-item">
        <div class="list-item-title">承诺办结时限</div>
        <div class="list-item-val">{{ metterObj.promiseCompletion }}日</div>
      </div>
      <div class="list-item">
        <div class="list-item-title">当前状态</div>
        <div class="list-item-val">
          {{ metterObj.handleStatus | FilterStatus }}
        </div>
      </div>
      <div class="list-item">
        <div class="list-item-title">咨询电话</div>
        <div class="list-item-val">{{ metterObj.consultingPhone }}</div>
      </div>
      <div class="list-item" v-show="metterObj.handleStatus!='0'">
        <div class="list-item-title">受理意见</div>
        <div class="list-item-val">
          {{ acceptComments ? acceptComments : "无" }}
        </div>
      </div>
      <div class="list-item" v-if="metterObj.handleStatus=='2' || metterObj.handleStatus=='3'">
        <div class="list-item-title">办结意见</div>
        <div class="list-item-val">
          {{ concludingOpinion ? concludingOpinion : "无" }}
        </div>
      </div>
      <div class="list-item" v-if="metterObj.applyFileAttachments && metterObj.applyFileAttachments.length > 0">
        <div class="list-item-title">结果材料</div>
        <div class="list-item-val">
          <div class="val-list" v-for="(item, index) in metterObj.applyFileAttachments" :key="'list'+index" @click="handleDown(item, item.name)">
             <span class="val-name">{{item.name}}</span>
             <span class="val-btn">{{fileType.indexOf(item.fileType)!=-1?'下载':'预览'}}</span>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="80%"
      >
        <div class="imgDialog">
          <img :src="imgUrl"/>
        </div>
    </el-dialog>
  </div>
</template>

<script>
// import * as req from "@/api/user/index";
import { download, refreshFileToken, downloadImg } from "@/api/user/index";
import { appGlobalData } from "@/utils/index";
export default {
  data() {
    return {
      metterObj: {},
      acceptComments: "", //受理意见
      concludingOpinion: "", //办结意见
      imgType: ["png","jpg","jpeg","JPG","PNG","JPEG"],
      fileType: ['doc', 'docx', 'xlsx', 'xls', 'ppt', 'pptx', 'pdf'],
      dialogVisible: false,
    };
  },
  mounted() {
    if (this.$route.params) {
      this.metterObj = this.$route.params;
      this.metterObj.checkHistoryList.forEach((el) => {
        if (el.handleStatus == 1) {
          this.acceptComments = el.checkOpinion;
        } else if (el.handleStatus == 2 || el.handleStatus == 3) {
          this.concludingOpinion = el.checkOpinion;
        }
      });
    }
    this.$forceUpdate();
  },
  filters: {
    FilterStatus(val) {
      if (val == 0 || val == "0") {
        return "已提交";
      } else if (val == "1" || val == 1) {
        return "已受理";
      }else if(val == "2" || val == 2){
        return '已退回'
      } else {
        return "已办结";
      }
    },
  },
  methods: {
    isiOSDevice() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },
    checkUrl() {
      let ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true; // '微信浏览器'
      } else {
        return false; // 其他浏览器
      }
    },
    // 预览下载
    // handleDown(file) {
    //   if (this.imgType.indexOf(file.fileType) == -1) {
    //     let link = document.createElement("a");
    //       link.style.display = "none";
    //       link.href = appGlobalData.link + `prod-api/transfer/common/download?filePath=${file.filePath}&fileName=${file.fileName}`
    //       console.log('下载路径', link.href)
    //       let filename = file.fileName;
    //       link.setAttribute("download", filename);
    //       document.body.appendChild(link);
    //       link.click();
    //       document.body.removeChild(link) // 下载完成移除元素
    //   } else {
    //     downloadImg({
    //       filePath: file.filePath,
    //       fileName: file.fileName,
    //     }).then((res) => {
    //       this.imgUrl = 'data:'+res.data.data.mimeType+';base64,' + res.data.data.base64;
    //       this.dialogVisible = true;
    //     });
    //   }
    // },
    handleDown(file, title) {
      if (this.imgType.indexOf(file.fileType) != -1) {
        downloadImg({
          filePath: file.filePath,
          fileName: file.fileName,
        }).then((res) => {
          this.imgUrl = 'data:'+res.data.data.mimeType+';base64,' + res.data.data.base64;
          this.dialogVisible = true;
        });
      } else if (this.fileType.indexOf(file.fileType) != -1 || this.isiOSDevice() || !this.checkUrl()) { // 苹果手机支持所有
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = appGlobalData.link + `prod-api/transfer/common/download?filePath=${file.filePath}&fileName=${file.fileName}`
        console.log('下载路径', link.href)
        let filename = file.name;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link) // 下载完成移除元素
      } else {
        // 微信无法下载
        this.$router.push({
            name: "DownloadPage",
            query: {
              name:file.fileName,
              filePath: file.filePath,
              title: title,
              link: appGlobalData.link,
              fileName: file.name
            },
          });
      }

    },
  },
};
</script>

<style scoped>
.BusinessDetails {
  margin: 20px;
}
.list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 15px 0;
}
.list-item-title {
  font-size: 16px;
}
.list-item-val {
  font-size: 14px;
  color: gray;
  width: 65%;
  text-align: right;
}
.val-list{
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}
.val-name{
  flex: 1;
  width: 100%;
  /* white-space: inherit; */
  word-break: break-all;
  /* overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; */
}
.val-btn{
  width: 60px;
  cursor: pointer;
  color: #409eff;
}
.imgDialog{
  width: 100%;
  height: 300px;
  position: relative;
}
.imgDialog img{
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
</style>