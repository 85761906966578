import axios from "axios";
import { MessageBox } from "element-ui";
import { encrypt } from "@/utils/rsaUtils";
import errorCode from '@/utils/errorCode'
axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
const service = axios.create({
  // baseURL:process.env.VUE_APP_BASE_API,
  // baseURL:'http://10.100.0.210:9080',
  // baseURL:'http://192.168.255.18:20030',
  baseURL: process.env.NODE_ENV === "production" ? "/prod-api" : "/",
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  // 超时
  timeout: 20000,
});
service.interceptors.request.use(
  (config) => {
    // 对请求参数进行加密处理
    // if (localStorage.getItem("loginInfo")) {
    //   const pubKey = JSON.parse(localStorage.getItem("loginInfo")).publicKey;
    //   config.data = encrypt(JSON.stringify(config.data), pubKey);
    // }
    const isToken = (config.headers || {}).isToken
    let loginInfo = localStorage.getItem("loginInfo")?JSON.parse(localStorage.getItem("loginInfo")) : {}
    // 是否需要防止数据重复提交
    if (loginInfo.token && isToken) {
      delete config.headers.isToken
      config.headers['Authorization'] = loginInfo.token // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config;
  },
  (error) => {
    console.log(error, "error");
    return Promise.reject(error);
  }
);
service.interceptors.response.use(
  (response) => {
    console.log(response, 'response')
    const code = response.data.code || 200;
    // 获取错误信息
    const msg = errorCode[code] || response.data.msg || errorCode['default']
    if (code === 401) {
      if (localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo')).name) {  //已登录
        localStorage.removeItem('userInfo');
        localStorage.removeItem('loginInfo');
        location.href = '/index';
      } else {  //未注册
        MessageBox.confirm('未实名认证，请进行实名认证', '系统提示', {
          confirmButtonText: '实名认证',
          cancelButtonText: '取消',
          type: 'warning'
        }
        ).then(() => {
          location.href = '#/register'
        })
      }
    }else if(code == 403){
      MessageBox.confirm('未人脸认证，请进行人脸认证', '系统提示', {
        confirmButtonText: '人脸认证',
        cancelButtonText: '取消',
        type: 'warning'
      }
      ).then(() => {
        location.href = '#/faceAuthentication';
      })
    } else {
      return response
    }
  },
  (error) => {
    console.log(error, "error");
    //String(error).toLowerCase().indexOf('timeout')
    // if (error && error.stack.indexOf('timeout') > -1) {
    //   Message({
    //     message: '请求超时，请稍后重试！',
    //     type: 'error',
    //     duration: 3000,
    //   })
    // }
  }
);
export default service;
