<template>
  <div class="main">
    <div class="main_left">
      <!-- 地图分类导航 -->
      <div class="mapNavBar">
        <ul>
          <li
            v-for="(item, index) in mapTypeList"
            :class="showList == index ? 'on' : ''"
            :key="index"
            @click="changeList(item, index)"
          >
            {{ item.name }}
            <span></span>
          </li>
        </ul>
      </div>
      <!-- 地图搜索 -->
      <div class="search-box">
        <el-input
          style="color: gray"
          placeholder="请输入要查找的点位"
          v-model.trim="queryPrams.name"
          class="input-with-select"
          @keyup.enter.native="searchFn(queryPrams.name, true)"
        >
          <el-cascader
            :show-all-levels="false"
            v-if="showList == 0"
            placeholder="区域选择"
            :props="areaList"
            slot="prepend"
            v-model="addressValue"
            @change="changeNode"
          ></el-cascader>
          <i
            class="el-icon-search"
            slot="append"
            style="color: #000; font-size: 20px"
            @click="searchFn(queryPrams.name, true)"
          ></i>
        </el-input>
      </div>
      <!-- 地图组件 -->
      <map-container
        ref="map"
        :mapData="mapData"
        :type="queryPrams.type"
        :drawer="drawer"
        :serviceMapList="serviceMapList"
        @closeDrawerDetail="closeDrawerDetail"
        @showMarkDrawer="showMarkDrawer"
      ></map-container>

      <div class="dlog" v-show="drawerList">
        <p class="close_icon">
          <i class="el-icon-close" @click="drawerList = false"></i>
        </p>
        <el-row class="list" style="margin-top: 10px">
          <el-col
            :span="24"
            v-for="(item, index) in Addresslist"
            :key="index"
            style="cursor: pointer"
          >
            <div class="list-item" @click="showDetail(item)">
              {{ item.name }}
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- <div v-show="drawerDetail" class="dlogDetail">
        <div class="close_icon">
          <i class="el-icon-close" @click="drawerDetail = false"></i>
        </div>
        <div style="margin-top: 10px; padding: 20px">
          <p>{{ addressDetail.name }}</p>
          <div class="detail_item">
            <img src="../assets/img/address_icon.png" alt="" />
            <span>{{ addressDetail.address }}</span>
          </div>
          <div class="detail_item" v-show="addressDetail.workTime">
            <img src="../assets/img/workTime_icon.png" alt="" />
            <span>工作时间：{{ addressDetail.workTime }}</span>
          </div>
          <div class="detail_item" v-show="addressDetail.phoneNumbe">
            <img src="../assets/img/phone_icon.png" alt="" />
            <span> 咨询电话：{{ addressDetail.phoneNumbe }}</span>
          </div>
          <div style="text-align: right">
            <el-button
              v-show="addressDetail.isItem == '1'"
              size="mini"
              type="primary"
              @click="toDisclosure()"
              >事项公开</el-button
            >
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
  
  <script>
import MapContainer from "@/components/MapContainer/MapContainer";
import {
  queryList,
  queryDeptMatterRegion,
  regionVillagePoint,
  conditionTypeQuery,
} from "@/api/map/index";
export default {
  name: "index",
  components: { MapContainer },
  data() {
    return {
      queryPrams: {
        name: "", //搜索关键词
        type: 0,
        regionId: null,
      },
      Addresslist: [], //海量点根据经纬度组成的数组
      mapTypeList: [
        {
          type: 0,
          name: "政务服务",
        },
        {
          type: 1,
          name: "教育资源",
        },
        {
          type: 3,
          name: "医疗卫生",
        },
        {
          type: 2,
          name: "养老服务",
        },
      ],
      showList: false, //当前的点击地图类别
      // 点位列表，路由入口
      serviceMapList: [
        { name: "汉源", linkName: "HanYuan" },
        { name: "富庄", linkName: "FuZhuang" },
        { name: "富泉", linkName: "FuQuan" },
        { name: "小堡", linkName: "XiaoBao" },
        { name: "顺河", linkName: "ShunHe" },
        { name: "马烈", linkName: "MaLie" },
        { name: "富乡", linkName: "FuXiang" },
        { name: "宜东", linkName: "YiDong" },
        { name: "安乐", linkName: "AnLe" },
        { name: "皇木", linkName: "HuangMu" },
        { name: "大树", linkName: "DaShu" },
        { name: "九襄", linkName: "JiuXiang" },
        { name: "河南", linkName: "HeNan" },
        { name: "富林", linkName: "FuLin" },
        { name: "片马", linkName: "PianMa" },
        { name: "晒经", linkName: "ShaiJing" },
        { name: "清溪", linkName: "QingXi" },
        { name: "唐家", linkName: "TangJia" },
        { name: "前域", linkName: "QianYu" },
        { name: "乌斯", linkName: "WuSi" },
        { name: "永利", linkName: "YongLi" },
        { name: "坭美", linkName: "NiMei" },
      ],
      // 区域选择数据来源
      areaList: {
        lazy: true,
        async lazyLoad(node, resolve) {
          const nodes = [];
          let params = {
            method: "/mobile/regionVillagePoint",
            body: JSON.stringify({ pid: node.value, level: node.level }),
          };

          // 请求接口
          let { data } = await regionVillagePoint(params);
          // console.log(data,'结果')
          if (data && data.data && data.data.length > 0) {
            data.data.map((item) => {
              let obj = {
                value: item.regionId,
                label: item.name,
                leaf: item.leaf == 0, // 当 node.level 层级达到2级时, 就不再请求接口
                // 具体要多少级才不请求接口, 根据层级修改
              };
              nodes.push(obj);
            });
          }
          //重新加载节点
          resolve(nodes);
        },
      },
      addressValue: [], //选中区域
      drawerDetail: false, //点位详情弹窗
      drawerList: false, //点位列表
      drawer: false, //点击地图表达时展示的弹窗
      addressDetail: {}, //选中点位详情
      searchAddressList: [],
    };
  },
  computed: {
    mapData() {
      let list = this.Addresslist;
      var n = []; //一个新的临时数组
      //遍历当前数组
      for (var i = 0; i < list.length; i++) {
        //如果当前数组的第i已经保存进了临时数组，那么跳过，
        //否则把当前项push到临时数组里面
        if (n.indexOf(list[i]) == -1) n.push(list[i]);
      }
      return n;
    },
  },
  mounted() {
    // this.getList(this.queryPrams.name);
    // this.changeTypeList();
    this.$forceUpdate();
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  },
  created() {
    this.$emit("public_header", false);
    this.$forceUpdate();
  },
  methods: {
    // 地图类型切换时调用接口
    changeTypeList() {
      let obj = {
        method: "/mobile/conditionTypeQuery",
        body: JSON.stringify({ type: this.queryPrams.type }),
      };
      conditionTypeQuery(obj).then((res) => {
        if (res.data.code == 200) {
          let datalist = res.data.data;
          // let lnglat = [];
          datalist.forEach((element) => {
            // lnglat.push([Number(element.longitude), Number(element.latitude)]);
            element['position'] = [Number(element.longitude), Number(element.latitude)]
          });
          // for (let i = 0; i < datalist.length; i++) {
          //   datalist[i].position = lnglat[i];
          // }
          this.Addresslist = datalist;
        } else {
          this.$alert(
            `<i class="el-icon-warning" style="color:red;font-size:50px"></i> <div style="color:red;">${res.data.msg}</div>`,
            "",
            {
              dangerouslyUseHTMLString: true,
              center: true,
              showConfirmButton: false,
            }
          );
        }
      });
    },
    // 搜索
    async searchFn(val, flag) {
      this.drawerDetail = false;
      let res = await this.getList(val, flag);
      if (res && res.length > 1) {
        this.drawerList = true;
      }
    },
    // 展开详情
    showDetail(item) {
      this.Addresslist = [];
      this.Addresslist.push(item);
      let obj = {
        method: "/mobile/queryDeptMatterRegion",
        body: JSON.stringify({ id: item.id }),
      };
      // 查询是否有事项公开
      queryDeptMatterRegion(obj).then((res) => {
        if (res.data.code == 200) {
          this.drawerList = false;
          this.addressDetail = item;
          this.addressDetail.isItem = res.data.data.isItem;
          this.addressDetail.regionName = res.data.data.regionName;
          // this.drawerDetail = true;
        } else {
          this.$alert(
            `<i class="el-icon-warning" style="color:red;font-size:50px"></i> <div style="color:red;">${res.data.msg}</div>`,
            "",
            {
              dangerouslyUseHTMLString: true,
              center: true,
              showConfirmButton: false,
            }
          );
        }
      });
    },
    // 获取所有政务服务点位
    async getList(val,flag) {
      this.addressValue.length > 0
        ? (this.queryPrams.regionId =
            this.addressValue[this.addressValue.length - 1])
        : (this.queryPrams.regionId = null);
      let resArr;
      this.queryPrams.name = val;
      let api = '/mobile/conditionQuery';
      if (!flag) {
        api = '/mobile/conditionQueryMap';
      }
      let obj = {
        method: api,
        body: JSON.stringify(this.queryPrams),
      };
      await queryList(obj).then((res) => {
        this.Addresslist = [];
        if (res.data.code == 200) {
          if (res.data.data && res.data.data.length > 0) {
            let datalist = res.data.data;
            // let lnglat = [];
            datalist.forEach((element) => {
              // lnglat.push([
              //   Number(element.longitude),
              //   Number(element.latitude),
              // ]);
              element['position'] = [Number(element.longitude), Number(element.latitude)]
            });
            // for (let i = 0; i < datalist.length; i++) {
            //   datalist[i].position = lnglat[i];
            // }
            this.Addresslist = datalist;
            return (resArr = datalist);
          } else {
            this.$message.error("暂无匹配点位！");
            return (resArr = []);
          }
        } else {
          this.$alert(
            `<i class="el-icon-warning" style="color:red;font-size:50px"></i> <div style="color:red;">${res.data.msg}</div>`,
            "",
            {
              dangerouslyUseHTMLString: true,
              center: true,
              showConfirmButton: false,
            }
          );
          return (resArr = []);
        }
      });
      return resArr;
    },
    // 选择区域
    changeNode(val) {
      this.getList('', true);
    },
    // 地图分类切换
    changeList(item, index) {
      // console.log(item, index, '??')
      this.drawerList = false;
      this.drawerDetail = false;
      this.queryPrams.name = "";
      this.addressValue = [];
      this.queryPrams.type = item.type;
      this.changeTypeList();
      this.showList = index; //为点击菜单添加样式
    },
    // 事项公开
    toDisclosure() {
      let that = this;
      sessionStorage.setItem("topTitle", this.addressDetail.regionName);
      let i = that.serviceMapList.findIndex(
        (el) => el.name == this.addressDetail.regionName.slice(0, 2)
      );
      if (i != -1) {
        that.$router.push({
          name: that.serviceMapList[i].linkName,
        });
      }
    },
    // 事项详情页
    toDetail(id, name) {
      let obj = { deptMatterId: id, name };
      this.$router.push({
        name: "ServiceGuide",
        params: { deptMatterId: id, name },
      });
      sessionStorage.setItem("matterDetails", JSON.stringify(obj));
    },
    // 地图点标记事件时，关闭搜索结果弹窗
    closeDrawerDetail(newVal) {
      this.drawerList = newVal;
      this.drawerDetail = newVal;
    },
    showMarkDrawer(newVal) {
      this.drawer = newVal;
    },
  },
  beforeRouteEnter(to, from, next) {
    let that = this;
    // to:获取你要跳转的路由信息
    // from: 获取你从哪个路由来的信息
    // next: （放行函数）
    // 从办事指南过来，则办理部门名称搜索，并展示点位信息，否则直接放行
    if (from.name == "ServiceGuide") {
      if (sessionStorage.getItem("matterDetails")) {
        let name = JSON.parse(
          sessionStorage.getItem("matterDetails")
        ).addressName;
        next((vm) => {
          vm.searchFn(name);
        }); // 直接放行
      }
    } else {
      // next(); // 直接放行
      next((vm) => {
          vm.changeTypeList();
        }); // 直接放行
    }
  },
};
</script>
  
  <style scoped>
.title-text {
  clear: both;
  color: #fff;
  margin: 0 auto;
  width: 750px;
  padding-top: 25px;
}

.detail {
  text-align: left;
  text-indent: 2em;
  font-size: 15px;
  line-height: 22px;
  padding-top: 10px;
}
.dlog {
  position: fixed;
  bottom: 0;
  background-color: #fff;
  border-radius: 5px 5px 0 0;
  width: 100%;
  box-sizing: border-box;
  height: 50%;
  overflow-y: auto;
  z-index: 1000;
}
.dlogDetail {
  position: fixed;
  bottom: 0px;
  background-color: #fff;
  border-radius: 5px 5px 0 0;
  width: 100%;
  box-sizing: border-box;
}
.close_icon {
  text-align: right;
  position: fixed;
  right: 5px;
  width: 100%;
  background-color: #fff;
  z-index: 1000;
}
.dlogDetail p {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
}
.list-item {
  text-align: left;
  padding: 15px;
  border-bottom: 1px solid #dddddd;
}
body,
p {
  margin: 0;
}

a {
  color: #000;
  text-decoration: none;
}

ul,
li {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}
.logo {
  padding-top: 15px;
  float: left;
  margin-left: 210px;
}

.main {
  width: 100%;
  position: relative;
}

.main_left {
  /* min-height: 680px;
  max-height: 932px;
  position: relative; */
  position: relative;
}
/deep/.el-input-group__append {
  border: none;
  background-color: #fff;
}
/deep/.el-input-group--prepend .el-input__inner {
  border: none;
}
/deep/.el-input-group__prepend {
  border: none;
  background-color: #fff;
  color: #1296db;
  width: 102px;
  padding: 0 10px;
}
/deep/.el-input__inner {
  border: none;
}
/* 右侧列表 */
.main_left_tab3 {
  overflow-y: scroll;
  max-height: 630px;
  background-color: #fff;
}
.search-box {
  /* position: absolute; */
  position: fixed;
  background-color: #fff;
  z-index: 1000;
  box-sizing: border-box;
  width: 96%;
  top: 50px;
  border-radius: 5px;
  left: 2%;
}
/* .main_left_tab3 div {
    display: none;
  } */

.main_left_tab3 div ul {
  margin: 0 10px 10px 10px;
  list-style: none;
  padding: 0;
}

.main_left_tab3 ul li {
  border: 1px solid #e5e5e5;
  /* background: url(../img/map_point.png) no-repeat 9px 15px; */
  /* padding: 10px 0px 10px 40px; */
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
  background-color: #fff;
  text-align: left;
}

.type-title {
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0 0 0;
}

.main_left_tab3 .zhengwu .more-tips {
  font-size: 13px;
  cursor: pointer;
}

/*.main_left_tab3 ul li:hover {
    background: #0492fa;
    color: #fff;
  }*/

.main_left_tab3 .zhengwu #xzfwdt .banshiBtn {
  width: 80px;
  height: 35px;
  display: inline-block;
  text-align: center;
  line-height: 35px;
  background-color: #0090ff;
  color: #fff;
  border-radius: 5px;
  margin-right: 10px;
  /*display: none;*/
  /*隐藏办事指南*/
}

.banshiBtn {
  width: 80px;
  height: 35px;
  display: inline-block;
  text-align: center;
  line-height: 35px;
  background-color: #0090ff;
  color: #fff;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
}

.main_left_tab3 .zhengwu #xzfwdt li > p:nth-child(1) {
  display: flex;
  justify-content: space-between;
}

/* .main_left_tab3 ul li:hover {
      background-color: #f2f2f2;
      cursor: pointer;
  } */

.main_left_tab3 ul li h4 {
  /* color: #fff; */
  font-weight: 400;
}

.service-titleBox {
  display: flex;
  align-items: center;
}

.service-titleBox-title {
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: 70%;
}

.main_left_tab3 ul p {
  line-height: 25px;
  font-size: 14px;
  /* color: #fff; */
}

.main_left_tab3 ul b {
  font-weight: 400;
}

.main_left .mapNavBar {
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  /* position: absolute; */
  position: fixed;
  z-index: 1000;
  background-color: #fff;
}

.main_left .mapNavBar > ul {
  display: flex;
  justify-content: space-around;
}

.main_left .mapNavBar > ul > li {
  /* background: #0492FA;
      color: #fff; */
  padding: 5px;
  width: 80px;
  text-align: center;
  background: #fff;
  color: #000;
  font-weight: bold;
  cursor: pointer;
}
/* 地图logo隐藏 */
.amap-logo,
.amap-copyright {
  display: none !important;
}

/* 地图点位图标 */
/* .amap-icon {width: 19px !important;height: 32px !important;} */
/* .amap-icon img{width:100% !important;}  */
.on {
  color: #0492fa !important;
}
.on > span {
  display: block;
  height: 3px;
  width: 40px;
  margin: 0 auto;
  background-color: #0492fa;
  margin-top: 5px;
}

/* 地图信息窗样式 */
.amap-info-contentContainer {
  width: 56vh;
  /* height: 120px; */
  /* background-color: #fff;
    border: 1px solid #aaa;
    padding: 10px;
    text-align: left; */
}
/deep/.amap-logo {
  display: none !important;
}
/deep/.amap-copyright,
.amap-logo {
  display: none !important;
}
.amap-info-contentContainer h4 {
  color: #148aff;
  font-size: 16px;
  line-height: 25px;
  font-weight: 400;
  margin: 0;
}

.amap-info-contentContainer b {
  font-weight: 400;
  color: #148aff;
}

.amap-info-contentContainer p {
  margin: 10px 0;
}
</style>
  