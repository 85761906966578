<template>
  <div class="XiaoBao" v-loading="loading">
    <!--四季-->
    <div class="spring">
      <div class="banner">
        <!-- <img width="100%" src="../../../assets/img/XiaoBao/banner.jpg" alt="" srcset=""> -->
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item,index) in springImgList" :key=index>
            <img
              width="100%"
              v-lazy="item.imgUrl" ref="imgH" @load="imgLoad"
              alt=""
              srcset=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/XiaoBao/jianjie.png" alt="">
          <div class="desc">
            <p>
              在汉源县小堡乡农户家的果园，阵阵黄果柑的清香扑鼻而来。放眼望去，一棵棵绿油油的黄果柑树上挂满了金黄的果子，绿黄相间，甚是惹眼。
            </p>
            <p>
              春季，正是黄果柑成熟上市的时节。在小堡乡的田间地头，金灿灿的黄果柑挂满枝头，果林深处，村民正忙着采摘销售黄果柑。
            </p>
            <p>
              目前小堡乡的黄果柑种植面积已达1500余亩，产量达到400万斤左右。依托脱贫攻坚和乡村振兴政策，小堡乡致力于黄果柑产业全面发展。果农们不断学习现代化果树管理技术，利用电商+线下销售的方式，增加了黄果柑的销量和自己的收入，同时也带动了汉源旅游业的发展。
            </p>
          </div>
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px;text-align:center;">
          <img src="../../../assets/img/orange.png" alt="">
          <div class="bottom_br"></div>
        </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices :mapId="mapId" :name="name" color="#DF7E27"></GovernmentServices>
    </div>
  </div>
</template>
<script>


import GovernmentServices from "@/components/GovernmentServices/index";
import { getServerDate, matterQuery } from "@/api/map/index"
import { mixin } from "../../../mixin";
export default {
  name: 'XiaoBao',
  components: { GovernmentServices },
  data() {
    return {
      matterList: [],  //事项列表
      villages: [],  //乡镇列表
      name:'小堡藏族彝族乡',
      keywords:'',
      springImgList: [
      { imgUrl: require("../../../assets/img/XiaoBao/banner.jpg") },
      { imgUrl: require("../../../assets/img/XiaoBao/banner1.jpg") },
      ],
    };
  },
  mixins:[mixin],
  created() {
    this.$emit('public_header', false);

  },
};
</script>
<style scoped>
.banner {
  width: 100%;
  min-height: 215px;
  max-height: 427px;
}
/* .banner img{
  height: 100%;
} */
.con1 {
  position: relative;
  margin-top: -5px;
}
.con1 img{
  width: 100%;
}
.desc {
  font-size: 14px;
  color: #fff;
  position: absolute;
  top: 15%;
  left: 29%;
  overflow-y: auto;
  text-indent: 1em;
  width: 64%;
  height: 65%;
}
.search-box {
  margin: 0 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}
/deep/.spring .el-input__suffix{
  color: #DF7E27;
  font-size: 20px;
}
/deep/.el-carousel__indicator.is-active button {
  background-color: #DF7E27;
}
/deep/.el-carousel__button {
  background-color: gray;
}
</style>