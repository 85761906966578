<!-- 特色乡村--政务服务板块 -->
<template>
  <div class="con">
    <div>
      <div class="tb_box" v-show="name == '县本级'">
        <div class="tb_tit" :style="{ '--color': color }">
          <span
            :class="activeName == '1' ? 'is_active' : 'tb_tit_item'"
            @click="changeTab('1')"
            >按生命周期</span
          >
          <span
            :class="activeName == '2' ? 'is_active' : 'tb_tit_item'"
            @click="changeTab('2')"
            >按部门</span
          >
        </div>
      </div>
      <div class="tb_cont">
        <div class="list" v-show="activeName == '1'">
          <div class="tab-box">
            <el-button
              :style="{
                color: color,
                borderColor: color,
                '--activebjColor': color,
              }"
              size="medium"
              class="serviceBtn"
              :class="{ activeButton: activeServiceType == '1' }"
              @click="handleClick(1)"
              >个人服务</el-button
            >
            <el-button
              size="medium"
              :style="{
                color: color,
                borderColor: color,
                '--activebjColor': color,
              }"
              class="serviceBtn"
              :class="{ activeButton: activeServiceType == '2' }"
              @click="handleClick(2)"
              >法人服务</el-button
            >
          </div>
          <!-- 个人服务 -->
          <el-row
            :gutter="20"
            v-show="activeName == '1' && activeServiceType == '1'"
          >
            <el-col
              :span="8"
              v-for="(item, index) in PersonalServiceList"
              :key="index"
              @click.native="toLifeCycle(item, '1')"
            >
              <div class="list-item">
                <div class="svg_box">
                  <div class="shade"></div>
                  <svgIcon
                    :icon-class="setBgColor(item.name)"
                    :bgcolor="color"
                  ></svgIcon>
                </div>
                <span
                  style="
                    color: #000;
                    width: 69%;
                    display: inline-block;
                    text-align: right;
                    margin-right: 5px;
                  "
                  >{{ item.name }}</span
                >
              </div>
            </el-col>
          </el-row>
          <!-- 法人服务 -->
          <el-row
            :gutter="20"
            v-show="activeName == '1' && activeServiceType == '2'"
          >
            <el-col
              :span="8"
              v-for="(item, index) in CorporateServicesList"
              @click.native="toLifeCycle(item, '2')"
              :key="index"
            >
              <div class="list-item">
                <div class="svg_box">
                  <div class="shade"></div>
                  <svgIcon
                    :icon-class="setBgColor(item.name)"
                    :bgcolor="color"
                  ></svgIcon>
                </div>
                <span
                  style="
                    color: #000;
                    width: 69%;
                    display: inline-block;
                    text-align: right;
                    margin-right: 5px;
                  "
                  >{{ item.name }}</span
                >
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- 部门列表 -->
      <el-carousel
        height="311px"
        :autoplay="true"
        v-show="activeName == '2'"
        arrow="hover"
      >
        <el-carousel-item>
          <el-row :gutter="20" class="dept_list" v-show="activeName == '2'">
            <el-col
              :span="8"
              v-for="(item, index) in deptList.slice(0, 12)"
              :key="index"
              @click.native="toLifeCycle(item, '3')"
            >
              <div class="dept_list_item">{{ item.deptName }}</div>
            </el-col></el-row
          >
        </el-carousel-item>
        <el-carousel-item>
          <el-row :gutter="20" class="dept_list" v-show="activeName == '2'">
            <el-col
              :span="8"
              v-for="(item, index) in deptList.slice(13, 25)"
              :key="index"
              @click.native="toLifeCycle(item, '3')"
            >
              <div class="dept_list_item">{{ item.deptName }}</div>
            </el-col></el-row
          >
        </el-carousel-item>
        <el-carousel-item>
          <el-row :gutter="20" class="dept_list" v-show="activeName == '2'">
            <el-col
              :span="8"
              v-for="(item, index) in deptList.slice(26, deptList.length)"
              :key="index"
              @click.native="toLifeCycle(item, '3')"
            >
              <div class="dept_list_item">{{ item.deptName }}</div>
            </el-col></el-row
          >
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import { lifeCycleMatter, GetdeptList } from "@/api/map/index";
import svgIcon from "@/components/SvgIcon";
export default {
  name: "GovernmentServices",
  components: { svgIcon },
  props: {
    matterList: [], //事项列表
    mapId: [], //当前区县点位id
    name: "", //当前区县名称
    color: "",
  },
  data() {
    return {
      topTitle: this.$route.params.topTitle,
      deptId: "",
      activeName: "1",
      activeServiceType: "1",
      PersonalServiceList: [], //个人服务列表
      CorporateServicesList: [], //法人服务列表
      itemImg: {
        其他: "11",
        工作: "P_2",
        升学: "P_1",
        购房: "P_3",
        结婚: "P_4",
        生育: "P_5",
        失业: "P_6",
        创业: "P_7",
        迁居: "P_8",
        退休: "P_9",
        后事: "P_10",
        开办企业: "L_1",
        申请资质: "L_2",
        投资立项: "L_3",
        扩大生产: "L_4",
        引进人才: "L_5",
        办理社保: "L_6",
        申请专利: "L_7",
        缴纳税费: "L_8",
        申请破产: "L_9",
        申请贷款: "L_10",
      },
      deptList: [], //部门列表
      keywords: "",
    };
  },
  mounted() {
    if (sessionStorage.getItem("topTitle")) {
      this.topTitle = sessionStorage.getItem("topTitle");
    }
    // if (sessionStorage.getItem("deptObj")) {
    //     this.deptId = JSON.parse(sessionStorage.getItem("deptObj")).deptId;
    //     // this.id = JSON.parse(sessionStorage.getItem("deptObj")).id;
    // }
    this.getPersonalServices();
  },
  computed: {},
  methods: {
    // 获取个人服务列表
    getPersonalServices() {
      let params = {
        method: "/mobile/lifeCycleMatter",
        body: JSON.stringify({ serviceTypeId: "1" }),
      };
      lifeCycleMatter(params).then((res) => {
        if (res.data.code == 200 && res.data.data.length > 0) {
          this.PersonalServiceList = res.data.data;
        }
      });
    },
    // 获取法人服务列表
    getCorporateservices() {
      let params = {
        method: "/mobile/lifeCycleMatter",
        body: JSON.stringify({ serviceTypeId: "2" }),
      };
      lifeCycleMatter(params).then((res) => {
        if (res.data.code == 200 && res.data.data.length > 0) {
          this.CorporateServicesList = res.data.data;
        }
      });
    },
    // 获取部门列表
    GetdeptList() {
      let obj = { method: "/mobile/deptList", body: "" };
      GetdeptList(obj).then((res) => {
        // console.log(res, '部门列表')
        if (res.data.data && res.data.data.length > 0) {
          this.deptList = res.data.data;
        }
      });
    },
    // 按生命周期/部门
    changeTab(val) {
      if (val == "1") {
        this.getPersonalServices();
        this.activeName = "1";
        this.activeServiceType = "1";
      } else {
        //查部门
        this.activeName = "2";
        this.GetdeptList();
      }
    },
    // 个人服务/法人服务切换
    handleClick(val) {
      if (val == "1") {
        this.getPersonalServices();
        this.activeName = "1";
        this.activeServiceType = "1";
      } else {
        this.getCorporateservices();
        this.activeName = "1";
        this.activeServiceType = "2";
      }
    },
    // 生命周期详情页
    toLifeCycle(item, type) {
      if (type == "1" || type == "2") {
        let obj = {
          lifeCycleId: item.lifeCycleId,
          name: this.name,
          serviceTypeId: type,
          deptId: this.deptId,
          id: this.mapId,
        };
        this.$router.push({
          name: "LifeCycle",
          params: obj,
        });
        if (this.name == "县本级") {
          obj.name = "汉源县政务服务中心";
        }
        sessionStorage.setItem("lifeCycleDetails", JSON.stringify(obj));
      } else {
        //根据部门查询
        let obj = {
          lifeCycleId: item.lifeCycleId,
          name: item.deptName,
          serviceTypeId: "",
          deptId: item.deptId,
        };
        this.$router.push({
          name: "LifeCycle",
          params: obj,
        });
        sessionStorage.setItem("lifeCycleDetails", JSON.stringify(obj));
      }
      sessionStorage.setItem("topTitle", this.name);
    },
    // 设置每项生命周期背景颜色
    setBgColor(name) {
      switch (name) {
        case "其他":
          return this.itemImg["其他"];
          break;
        case "工作":
          return this.itemImg["工作"];
          break;
        case "开办企业":
          return this.itemImg["开办企业"];
          break;
        case "投资立项":
          return this.itemImg["投资立项"];
          break;
        case "申请资质":
          return this.itemImg["申请资质"];
          break;
        case "升学":
          return this.itemImg["升学"];
          break;
        case "购房":
          return this.itemImg["购房"];
          break;
        case "结婚":
          return this.itemImg["结婚"];
          break;
        case "生育":
          return this.itemImg["生育"];
          break;
        case "失业":
          return this.itemImg["失业"];
          break;
        case "创业":
          return this.itemImg["创业"];
          break;
        case "迁居":
          return this.itemImg["迁居"];
          break;
        case "退休":
          return this.itemImg["退休"];
          break;
        case "后事":
          return this.itemImg["后事"];
          break;
        case "扩大生产":
          return this.itemImg["扩大生产"];
          break;
        case "引进人才":
          return this.itemImg["引进人才"];
          break;
        case "办理社保":
          return this.itemImg["办理社保"];
          break;
        case "申请专利":
          return this.itemImg["申请专利"];
          break;
        case "缴纳税费":
          return this.itemImg["缴纳税费"];
        case "申请贷款":
          return this.itemImg["申请贷款"];
          break;
        case "申请破产":
          return this.itemImg["申请破产"];
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style  scoped>
.con {
  margin: 0 20px;
}

.tab-box {
  margin-bottom: 15px;
  width: 100%;
  text-align: center;
}

.btn {
  color: #3ca4ec;
  border: 1px solid #a7d4f5;
}

.text_btn {
  color: #a2a2a2;
}
.serviceBtn {
  border-radius: 16px;
  font-weight: bold;
}
.activeButton {
  background-color: var(--activebjColor);
  color: #fff !important;
}

.active_textButton {
  color: #31a9ff;
}

.list {
  display: flex;
  flex-wrap: wrap;
  margin: 15px 5px;
  color: #fff;
}

.list-item {
  min-height: 45px;
  max-height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  background-color: #fff;
  font-size: 14px;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 10px 0;
}

.dept_list {
  margin-top: 20px;
}

.dept_list_item {
  height: 40px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-bottom: 20px;
  line-height: 40px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 10px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #fff;
  border-radius: 5px;
  font-size: 14px;
}

.list-item .list-item-title,
.list-item ul > li {
  margin: 15px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list-item .list-item-title {
  font-size: 20px;
  color: #fff;
  cursor: pointer;
}

.li-circle {
  width: 6px;
  height: 6px;
  background-color: #fff;
  display: inline-block;
  border-radius: 50%;
  margin-right: 5px;
  margin-bottom: 3px;
}

.tb_tit {
  height: 40px;
  text-align: left;
  background-color: rgb(8, 55, 128);
  color: #fff;
  padding: 0px 20px 9px 20px;
  border-radius: 5px 5px 0 0;
}

.tb_tit_item {
  display: inline-block;
  width: 85px;
  height: 40px;
  line-height: 40px;
  font-weight: 550;
  cursor: pointer;
  margin-right: 20px;
}

.is_active {
  border-bottom: 2px solid #fff;
  display: inline-block;
  height: 40px;
  line-height: 50px;
  margin-right: 20px;
  font-weight: 550;
}
.svg_box {
  width: 30%;
  line-height: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.svg_box .shade {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 2px solid #fff;
  z-index: 1000;
}
</style>