
/* 产引入encryptlong实现数据RSA加密 */
import Encrypt from 'encryptlong' // encryptlong是基于jsencrypt扩展的长文本分段加解密功能。
// import { Base64 } from 'js-base64'
// 加密，公钥加密
export function encrypt (data, publicKey) {
  var encryptor = new Encrypt()

  // const decodedStr = Base64.decode(publicKey)
  encryptor.setPublicKey(publicKey)

  // console.log('****decodedStr***:' + decodedStr)
  // 如果是对象/数组的话，需要先JSON.stringify转换成字符串
  const result = encryptor.encryptLong(data)
  return result
}
export function decrypt (data, privateKey) {
  var encryptor = new Encrypt()
  // const decodedStr = Base64.decode(privateKey)
  // console.log(decodedStr)
  encryptor.setPrivateKey(privateKey)
  // 如果是对象/数组的话，需要先JSON.stringify转换成字符串
  var result = encryptor.decryptLong(data)
  return result
}
