<!-- 乡镇 -->
<template>
  <div class="villagesAndTowns" v-loading="loading">
    <img class="banner" src="../../assets/img/xiangzhen-banner.jpg" alt="" />

    <!-- <div class="con1">
      <img src="../../assets/img/xiangzhen_jianjie.png" alt="" />
      <div class="desc">
        汉源县辖12个镇(富林镇、九襄镇、乌斯河镇、宜东镇、富庄镇、清溪镇、大树镇、皇木镇、唐家镇、富泉镇、安乐镇、前域镇),9个乡(富乡乡、马烈乡、河南乡、晒经乡、小堡藏族彝族乡、片马彝族乡、坭美彝族乡、永利彝族乡、顺河彝族乡)。
      </div>
    </div> -->
    <div class="con2">
      <el-row :gutter="20" class="dept_list">
        <el-col :span="8" v-for="item in villages" :key="item.mapId">
          <div class="dept_list_item">
            <img class="item_bg" :src="item.img" alt="" srcset="" />
            <div
              style="position: relative; z-index: 100"
              @click="toPage(item.url, item.hanYuanMapMobileVOs)"
            >
              {{ item.name }}
            </div>
          </div>
        </el-col></el-row
      >
    </div>
  </div>
</template>

<script>
import { getVillages } from "@/api/map/index";
export default {
  data() {
    return {
      villages: [],
      loading: false,
    };
  },
  mounted() {
    this.GetVillages();
    this.$forceUpdate();
  },
  methods: {
    // 获取乡村列表
    GetVillages() {
      // type为3查询乡镇列表
      let obj = {
        method: "/mobile/getVillages",
        body: JSON.stringify({ type: 3 }),
      };
      this.loading = true;
      getVillages(obj).then((res) => {
        // console.log(res, '乡镇列表')
        if (res.status == 200) {
          if (res.data.data && res.data.data.length > 0) {
            let arrList = res.data.data;
            for (let index = 0; index < arrList.length; index++) {
              switch (arrList[index].name.slice(0, 2)) {
                case "安乐":
                  arrList[index].url = "AnLe";
                  arrList[index].img = require("../../assets/img/le.png");
                  break;
                case "大树":
                  arrList[index].url = "DaShu";
                  arrList[index].img = require("../../assets/img/shu.png");
                  break;
                case "富林":
                  arrList[index].url = "FuLin";
                  arrList[index].img = require("../../assets/img/fu.png");
                  break;
                case "富泉":
                  arrList[index].url = "FuQuan";
                  arrList[index].img = require("../../assets/img/quan.png");
                  break;
                case "富乡":
                  arrList[index].url = "FuXiang";
                  arrList[index].img = require("../../assets/img/xiang.png");
                  break;
                case "富庄":
                  arrList[index].url = "FuZhuang";
                  arrList[index].img = require("../../assets/img/zhuang.png");
                  break;
                case "河南":
                  arrList[index].url = "HeNan";
                  arrList[index].img = require("../../assets/img/nan.png");
                  break;
                case "皇木":
                  arrList[index].url = "HuangMu";
                  arrList[index].img = require("../../assets/img/huang.png");
                  break;
                case "九襄":
                  arrList[index].url = "JiuXiang";
                  arrList[index].img = require("../../assets/img/xiang1.png");
                  break;
                case "马烈":
                  arrList[index].url = "MaLie";
                  arrList[index].img = require("../../assets/img/lie.png");
                  break;
                case "坭美":
                  arrList[index].url = "NiMei";
                  arrList[index].img = require("../../assets/img/ni.png");
                  break;
                case "片马":
                  arrList[index].url = "PianMa";
                  arrList[index].img = require("../../assets/img/pian.png");
                  break;
                case "前域":
                  arrList[index].url = "QianYu";
                  arrList[index].img = require("../../assets/img/yu.png");
                  break;
                case "清溪":
                  arrList[index].url = "QingXi";
                  arrList[index].img = require("../../assets/img/xi.png");
                  break;
                case "晒经":
                  arrList[index].url = "ShaiJin";
                  arrList[index].img = require("../../assets/img/shai.png");
                  break;
                case "顺河":
                  arrList[index].url = "ShunHe";
                  arrList[index].img = require("../../assets/img/shun.png");
                  break;
                case "唐家":
                  arrList[index].url = "TangJia";
                  arrList[index].img = require("../../assets/img/tang.png");
                  break;
                case "乌斯":
                  arrList[index].url = "WuSiHe";
                  arrList[index].img = require("../../assets/img/si.png");
                  break;
                case "小堡":
                  arrList[index].url = "XiaoBao";
                  arrList[index].img = require("../../assets/img/bao.png");
                  break;
                case "富泉":
                  arrList[index].url = "FuQuan";
                  arrList[index].img = require("../../assets/img/quan.png");
                  break;
                case "宜东":
                  arrList[index].url = "YiDong";
                  arrList[index].img = require("../../assets/img/dong.png");
                  break;
                case "永利":
                  arrList[index].url = "YongLi";
                  arrList[index].img = require("../../assets/img/li.png");
                  break;
                default:
                  break;
              }
            }
            this.villages = arrList;
            this.loading = false;
          }
        } else {
          this.$alert(
            `<i class="el-icon-warning" style="color:red;font-size:50px"></i> <div style="color:red;">${res.data.msg}</div>`,
            "",
            {
              dangerouslyUseHTMLString: true,
              center: true,
              showConfirmButton: false,
            }
          );
          this.loading = false;
        }
      });
    },
    // 跳转到特色乡村
    toPage(name, params) {
      let mapIds = JSON.stringify((params || []).map(item => item.id));
      sessionStorage.setItem("mapIds", mapIds)
      this.$router.push({
        name: name,
        params: { mapIds: mapIds },
      });
    },
  },
};
</script>

<style scoped>
.banner {
  width: 100%;
  min-height: 215px;
  max-height: 427px;
}
.banner img {
  height: 100%;
}
.con1 {
  position: relative;
  margin-top: -5px;
}
.con1 img {
  width: 100%;
}
.desc {
  font-size: 14px;
  color: #fff;
  position: absolute;
  top: 15%;
  left: 29%;
  overflow-y: auto;
  text-indent: 1em;
  width: 64%;
  height: 65%;
}
.dept_list {
  margin-top: 20px;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.dept_list_item {
  height: 65px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  text-align: left;
  margin-bottom: 20px;
  line-height: 65px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 10px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #fff;
  border-radius: 5px;
  font-size: 14px;
  position: relative;
}
.item_bg {
  position: absolute;
  right: 0;
}
</style>