<!-- 人脸认证 -->
<template>
  <div class="faceAuthentication">
    <div class="topTitle">人脸识别认证</div>
    <el-form
      :model="ruleForm"
      status-icon
      ref="ruleForm"
      label-width="95px"
      class="demo-ruleForm"
    >
      <el-form-item label="姓名" prop="name">
        <el-input
          disabled
          v-model="ruleForm.name"
          autocomplete="off"
          placeholder="请输入姓名"
        ></el-input>
      </el-form-item>
      <el-form-item label="身份证号码" prop="idCard">
        <el-input
          disabled
          v-model="ruleForm.idCard"
          autocomplete="off"
          placeholder="请输入身份证号码"
        ></el-input>
      </el-form-item>
    </el-form>
    <el-button
      style="width: 100%"
      type="primary"
      :loading="loading"
      @click="getfaceAuth"
      >开始认证</el-button
    >
  </div>
</template>

<script>
import * as req from "@/api/map/index";
import { appGlobalData } from "@/utils/index";
export default {
  data() {
    return {
      loading: false,
      ruleForm: {
        name: "",
        idCard: "",
      },
      sessionId: "",
    };
  },
  computed: {
    encryptName() {
      if (this.ruleForm.name) {
        // console.log(this.name,'?????????')
        return "*" + this.ruleForm.name.substring(1);
      } else {
        return "";
      }
    },
    encryptIdCard() {
      if (this.ruleForm.idCard) {
        return "**************" + this.ruleForm.idCard.substring(14);
      } else {
        return "";
      }
    },
  },
  mounted() {
    if (localStorage.getItem("loginInfo")) {
      this.sessionId = JSON.parse(localStorage.getItem("loginInfo")).sessionId;
    }
    if (this.$route.params && this.$route.params.name) {
      this.ruleForm.name = this.$route.params.name;
      this.ruleForm.idCard = this.$route.params.idCard;
    } else {
      this.ruleForm.name = this.$store.state.name;
      this.ruleForm.idCard = this.$store.state.idCard;
    }
    this.$forceUpdate();
  },
  methods: {
    getfaceAuth() {
      this.loading = true;
      let link = appGlobalData.link;
      // let redirectUrl = 'https://hanyuan.fengrenkeji.cn/#/Personal';
      let redirectUrl = link + '#/Personal';
      if (this.$route.params && this.$route.params.type && this.$route.params.type === 'ServiceGuide') {
        redirectUrl = link + '#/ServiceGuide';
        localStorage.setItem("isServiceGuideAuth", 'true')
      }
      req.faceAuth({ sessionId: this.sessionId,redirectUrl: redirectUrl}).then((res) => {
        if (res.data.code == 200) {
          this.loading = false;
          window.location.href = res.data.data.url;
        } else {
          this.loading = false;
          this.$alert(
            `<i class="el-icon-warning" style="color:red;font-size:50px"></i> <div style="color:red;">${res.data.msg}</div>`,
            "",
            {
              dangerouslyUseHTMLString: true,
              center: true,
              showConfirmButton: false,
            }
          );
        }
      });
    },
  },
};
</script>

<style scoped>
.faceAuthentication {
  margin: 20px;
}
/deep/.el-form-item__label {
  text-align: left;
}
/deep/.el-form-item {
  border-bottom: 1px solid #c2c2c2;
}
/deep/.el-input__inner {
  border: none;
}
/deep/ .el-input.is-disabled .el-input__inner {
  color: #000;
}
</style>