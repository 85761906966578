<template>
  <div class="NiMei" v-loading="loading">
    <!--春夏季：3-8月-->
    <div class="spring" v-if="nowMoth >= 3 && nowMoth <= 8">
      <div class="banner">
        <!-- <img
          width="100%"
          src="../../../assets/img/NiMei/spring_banner.jpg"
          alt=""
          srcset=""
        /> -->
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item,index) in springImgList" :key=index>
            <img
              width="100%"
              v-lazy="item.imgUrl" ref="imgH" @load="imgLoad"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/NiMei/spring_jianjie.png" alt="" />
        <div class="desc">
          莲花白是汉源县十大特色产业之一的高山蔬菜，主要分布在皇木镇、永利乡、坭美乡等地，全县种植面积约
          2.8万亩。因海拔、气候、土壤等条件非常适合种植莲花白，每年能种植两季。目前，第一季莲花白正火热上市，将持续销售到八月份。
        </div>
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px; text-align: center">
        <img src="../../../assets/img/olivine.png" alt="" />
        <div class="bottom_br"></div>
      </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"
        color="#98A545"
      ></GovernmentServices>
    </div>
    <!-- 秋季：7-9月 -->
    <div class="autumn" v-else-if="nowMoth >= 9 && nowMoth <= 11">
      <div class="banner">
        <!-- <img
          width="100%"
          src="../../../assets/img/NiMei/autumn_banner.jpg"
          alt=""
          srcset=""
        /> -->
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item,index) in autumnImgList" :key=index>
            <img
              width="100%"
              v-lazy="item.imgUrl" ref="imgH" @load="imgLoad"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/NiMei/autumn_jianjie.png" alt="" />
        <div class="desc">
          据坭美乡相关负责人介绍，种植户们常年坚持使用农家肥、科学种植农作物，有效保证了品质，口感到位同时农作物也很健康。
        </div>
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px; text-align: center">
        <img src="../../../assets/img/dark blue.png" alt="" />
        <div class="bottom_br"></div>
      </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"
        color="#1E3293"
      ></GovernmentServices>
    </div>
    <!-- 冬季：10-12月 -->
    <div class="winter" v-else>
      <div class="banner">
        <el-carousel :height="imgHeight">
          <el-carousel-item  v-for="(item,index) in imgList" :key=index>
            <img
              width="100%"
              v-lazy="item.imgUrl"
              ref="imgH" @load="imgLoad"
              alt=""
              srcset=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/NiMei/winter_jianjie.png" alt="" />
        <div class="desc">
          <p>
            当归具有补血活血，调经止痛，润肠通便之功效。常用于血虚萎黄，眩晕心悸，月经不调，经闭痛经，虛寒腹痛，风湿痹痛，跌扑损伤，痈疽疮疡，肠燥便秘。酒当归活血通经。用于经闭痛经，风湿痹痛，跌扑损伤。
          </p>
          <p>
            牛膝性味苦、甘、酸、平，入肝、肾经，具有活血通经、补肝肾、利水通淋、引火(血)
            下行等功效。牛膝去杂质，用水冲洗净，润透后切段，放入锅中加入适量的水，熬制后即可食用。
          </p>
        </div>
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px; text-align: center">
        <img src="../../../assets/img/dark brown.png" alt="" />
        <div class="bottom_br"></div>
      </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"
        color="#844532"
      ></GovernmentServices>
    </div>
  </div>
</template>
<script>


import GovernmentServices from "@/components/GovernmentServices/index";
import { getServerDate, matterQuery } from "@/api/map/index";
import { mixin } from "../../../mixin";
export default {
  name: "NiMei",
  components: {  GovernmentServices },
  data() {
    return {
      matterList: [], //事项列表
      villages: [], //乡镇列表
      name: "坭美彝族乡",
      keywords: "",
      imgList: [
        { imgUrl: require("../../../assets/img/NiMei/banner.jpg") },
        { imgUrl: require("../../../assets/img/NiMei/winter_banner1.jpg") },
        { imgUrl: require("../../../assets/img/NiMei/winter_banner2.jpg") },
      ],
      springImgList: [
        { imgUrl: require("../../../assets/img/NiMei/banner.jpg") },
        { imgUrl: require("../../../assets/img/NiMei/spring_banner.jpg") },
      ],
      autumnImgList: [
        { imgUrl: require("../../../assets/img/NiMei/banner.jpg") },
        { imgUrl: require("../../../assets/img/NiMei/autumn_banner.jpg") },
      ],
    };
  },
  mixins: [mixin],
  created() {
    this.$emit("public_header", false);
  },
};
</script>
<style scoped>
.banner {
  width: 100%;
  min-height: 215px;
  max-height: 427px;
} 
.con1 {
  position: relative;
  margin-top: -5px;
}
.con1 img {
  width: 100%;
}
.desc {
  font-size: 14px;
  color: #fff;
  position: absolute;
  top: 15%;
  left: 29%;
  overflow-y: auto;
  text-indent: 1em;
  width: 64%;
  height: 65%;
}
.search-box {
  margin: 0 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}
/deep/.spring .el-input__suffix {
  color: #98a545;
  font-size: 20px;
}
/deep/.autumn .el-input__suffix {
  color: #083780;
  font-size: 20px;
}
/deep/.winter .el-input__suffix {
  color: #844532;
  font-size: 20px;
}
/deep/.spring .el-carousel__indicator.is-active button {
  background-color: #98a545;
}
/deep/.autumn .el-carousel__indicator.is-active button {
  background-color: #083780;
}
/deep/.winter .el-carousel__indicator.is-active button {
  background-color: #844532;
}
/deep/.el-carousel__button {
  background-color: gray;
}
</style>