<template>
  <div class="HuangMu" v-loading="loading">
    <!-- 夏、秋、冬 -->
    <div
      class="winter"
      v-if="(nowMoth >= 6 && nowMoth <= 12) || (nowMoth >= 1 && nowMoth <= 2)"
    >
      <div class="banner">
        <!-- <img
          width="100%"
          src="../../../assets/img/HuangMu/winter_banner.jpg"
          alt=""
          srcset=""
        /> -->
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item,index) in winterImgList" :key=index>
            <img
              width="100%"
              v-lazy="item.imgUrl" ref="imgH" @load="imgLoad"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/HuangMu/winter_jianjie.png" alt="">
        <div class="desc">
          <p>包包菜又名包菜，学名莲花白，是一种脆甜美味的蔬菜。</p>
          <p>
            近年来，皇木镇依托高山低温气候、优质土壤等资源，大力调整农业产业结构，鼓励群众发展高山包包菜种植，目前种植面积超过1万亩,年产量超过1亿斤。皇木包包菜自然生长、品质达到生态标准，味道鲜美、脆甜可口，长期供应成都周边市场，并逐步拓展川内外及国内外市场。
          </p>
          <p>
            目前，皇木首季包包菜已完成销售，产量达3000万公斤，创造了3500万元的稳定收入。
          </p>
        </div>
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px;text-align:center;">
          <img src="../../../assets/img/olivine.png" alt="">
          <div class="bottom_br"></div>
        </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"
        color="#82901C"
      ></GovernmentServices>
    </div>
    <!-- 四季 -->
    <div class="spring" v-else>
      <div class="banner">
        <!-- <img
          width="100%"
          src="../../../assets/img/HuangMu/banner.jpg"
          alt=""
          srcset=""
        /> -->
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item,index) in springImgList" :key=index>
            <img
              width="100%"
              v-lazy="item.imgUrl" ref="imgH" @load="imgLoad"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/HuangMu/jianjie.png" alt="">
        <div class="desc">
          <p>皇木腊肉，川西特产。</p>
          <p>
            皇木腊肉，肥肉琥珀透，瘦肉金丝红，肥中含肌，瘦中润脂，瘦肉化渣，肥肉生香，色泽艳丽，味美可口。内含丰富的蛋白质和氨基酸，多种维生素和微量元素，属发酵类肉制品。
          </p>
          <p>
            皇木腊肉是川西平原腊肉和横断山膘肉文化融合的产物，既保留了大西南少数民族的粗狂自然的特质，又兼顾了川西平原食不厌精的美食特质。
          </p>
          <p>
            皇木腊肉因原产皇木镇而得名，皇木镇是清嘉古道上的重镇，因地处偏僻大山，一直不为人知，导致皇木腊肉养在深山人未识，近年来，随着饕客播主的深探细挖，皇木腊肉才作为新晋川味美食被世人所熟知。
          </p>
        </div>
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px;text-align:center;">
          <img src="../../../assets/img/red.png" alt="">
          <div class="bottom_br"></div>
        </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"
        color="#920C05"
      ></GovernmentServices>
    </div>
  </div>
</template>
<script>


import GovernmentServices from "@/components/GovernmentServices/index";
import { getServerDate, matterQuery } from "@/api/map/index";
import { mixin } from "../../../mixin";
export default {
  name: "HuangMu",
  components: {  GovernmentServices },
  data() {
    return {
      matterList: [], //事项列表
      villages: [], //乡镇列表
      name: "皇木镇",
      keywords: "",
      winterImgList:[
        {imgUrl:require('../../../assets/img/HuangMu/banner.jpg')},
        {imgUrl:require('../../../assets/img/HuangMu/winter_banner.jpg')},
      ],
      springImgList: [
        {imgUrl:require('../../../assets/img/HuangMu/banner.jpg')},
        {imgUrl:require('../../../assets/img/HuangMu/banner1.jpg')},
      ]
    };
  },
  mixins: [mixin],
  created() {
    this.$emit("public_header", false);
  },
};
</script>
<style scoped>
.banner {
  width: 100%;
  min-height: 215px;
  max-height: 427px;
}
.banner img{
  height: auto;
}
.con1 {
  position: relative;
  margin-top: -5px;
}
.con1 img{
  width: 100%;
}
.desc {
  font-size: 14px;
  color: #fff;
  position: absolute;
  top: 15%;
  left: 29%;
  overflow-y: auto;
  text-indent: 1em;
  width: 64%;
  height: 65%;
}
.search-box {
  margin: 0 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}
/deep/.spring .el-input__suffix{
  color: #751B01;
  font-size: 20px;
}
/deep/.winter .el-input__suffix{
  color: #82901C;
  font-size: 20px;
}
/deep/.spring .el-carousel__indicator.is-active button {
  background-color: #751B01;
}
/deep/.winter .el-carousel__indicator.is-active button {
  background-color: #82901C;
}
/deep/.el-carousel__button {
  background-color: gray;
}
</style>